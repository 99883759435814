define("ember-can/services/can", ["exports", "ember-can/services/abilities", "@ember/debug"], function (_exports, _abilities, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function deprecateMethod() {
    (false && !(false) && (0, _debug.deprecate)('Using CanService has been deprecated in favor of AbilitiesService. You should replace all occurrences with new `abilities` service.', false, {
      id: 'ember-can.can-service',
      until: '5.0.0',
      for: 'ember-can',
      since: {
        enabled: '4.1.0'
      }
    }));
  }
  class CanService extends _abilities.default {
    /**
     * @deprecated Use new AbilitiesService methods
     */
    parse() {
      deprecateMethod();
      return super.parse(...arguments);
    }

    /**
     * @deprecated Use new AbilitiesService methods
     */
    abilityFor() {
      deprecateMethod();
      return super.abilityFor(...arguments);
    }

    /**
     * @deprecated Use new AbilitiesService methods
     */
    valueFor() {
      deprecateMethod();
      return super.valueFor(...arguments);
    }

    /**
     * @deprecated Use new AbilitiesService methods
     */
    can() {
      deprecateMethod();
      return super.can(...arguments);
    }

    /**
     * @deprecated Use new AbilitiesService methods
     */
    cannot() {
      deprecateMethod();
      return super.cannot(...arguments);
    }
  }
  _exports.default = CanService;
});