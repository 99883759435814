define("@frontile/forms/components/form-field/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object/internals"], function (_exports, _component, _templateFactory, _component2, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    ...attributes
  >
    {{yield
      (hash
        id=this.id
        hintId=this.hintId
        feedbackId=this.feedbackId
  
        Label=(component "form-field/label" for=this.id size=@size)
        Hint=(component "form-field/hint" id=this.hintId size=@size)
        Feedback=(component "form-field/feedback" id=this.feedbackId size=@size)
  
        Input=(component "form-field/input" id=this.id size=@size)
        Textarea=(component "form-field/textarea" id=this.id size=@size)
        Checkbox=(component "form-field/checkbox" id=this.id size=@size)
        Radio=(component "form-field/radio" id=this.id size=@size)
      )
    }}
  </div>
  
  */
  {
    "id": "gT4hBee4",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[18,3,[[28,[37,1],null,[[\"id\",\"hintId\",\"feedbackId\",\"Label\",\"Hint\",\"Feedback\",\"Input\",\"Textarea\",\"Checkbox\",\"Radio\"],[[30,0,[\"id\"]],[30,0,[\"hintId\"]],[30,0,[\"feedbackId\"]],[50,\"form-field/label\",0,null,[[\"for\",\"size\"],[[30,0,[\"id\"]],[30,2]]]],[50,\"form-field/hint\",0,null,[[\"id\",\"size\"],[[30,0,[\"hintId\"]],[30,2]]]],[50,\"form-field/feedback\",0,null,[[\"id\",\"size\"],[[30,0,[\"feedbackId\"]],[30,2]]]],[50,\"form-field/input\",0,null,[[\"id\",\"size\"],[[30,0,[\"id\"]],[30,2]]]],[50,\"form-field/textarea\",0,null,[[\"id\",\"size\"],[[30,0,[\"id\"]],[30,2]]]],[50,\"form-field/checkbox\",0,null,[[\"id\",\"size\"],[[30,0,[\"id\"]],[30,2]]]],[50,\"form-field/radio\",0,null,[[\"id\",\"size\"],[[30,0,[\"id\"]],[30,2]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@size\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@frontile/forms/components/form-field/index.hbs",
    "isStrictMode": false
  });
  class FormField extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "id", (0, _internals.guidFor)(this));
    }
    get hintId() {
      return this.id + '-hint';
    }
    get feedbackId() {
      return this.id + '-feedback';
    }
  }
  _exports.default = FormField;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormField);
});