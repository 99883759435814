define("ember-svg-jar/inlined/dots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#D8D8D8\" fill-rule=\"evenodd\"><circle cx=\"2.5\" cy=\"2.5\" r=\"2.5\"/><circle cx=\"15.5\" cy=\"2.5\" r=\"2.5\"/><circle cx=\"28.5\" cy=\"2.5\" r=\"2.5\"/></g>",
    "attrs": {
      "width": "31",
      "height": "5",
      "viewBox": "0 0 31 5",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});