define("@frontile/core/components/collapsible/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/test-waiters", "@frontile/core/utils/safe-styles"], function (_exports, _component, _templateFactory, _component2, _object, _testWaiters, _safeStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    style={{this.styles}}
    ...attributes
    {{did-update this.update @isOpen}}
    {{on "transitionend" this.onTransitionEnd}}
  >
    {{yield}}
  </div>
  */
  {
    "id": "0258Va5B",
    "block": "[[[11,0],[16,5,[30,0,[\"styles\"]]],[17,1],[4,[38,0],[[30,0,[\"update\"]],[30,2]],null],[4,[38,1],[\"transitionend\",[30,0,[\"onTransitionEnd\"]]],null],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@isOpen\",\"&default\"],false,[\"did-update\",\"on\",\"yield\"]]",
    "moduleName": "@frontile/core/components/collapsible/index.hbs",
    "isStrictMode": false
  });
  const waiter = (0, _testWaiters.buildWaiter)('@frontile/core:collapsible');
  let Collapsible = (_class = class Collapsible extends _component2.default {
    // Internal value to track if open or not

    constructor(owner, args) {
      super(owner, args);
      _defineProperty(this, "isInitiallyOpen", false);
      _defineProperty(this, "waiterToken", void 0);
      _defineProperty(this, "isCurrentlyOpen", false);
      if (this.args.isOpen) {
        this.isInitiallyOpen = true;
        this.isCurrentlyOpen = true;
      }
    }
    get styles() {
      let styles = {};
      if (!this.isInitiallyOpen) {
        styles = _objectSpread(_objectSpread({}, styles), {}, {
          height: this.args.initialHeight || 0,
          opacity: this.args.initialHeight ? '1' : '0'
        });
      }
      if (this.args.initialHeight || !this.isInitiallyOpen) {
        styles = _objectSpread(_objectSpread({}, styles), {}, {
          overflow: 'hidden'
        });
      }
      return (0, _safeStyles.default)(styles);
    }
    update(element, _ref) {
      let [isOpen] = _ref;
      if (this.isCurrentlyOpen !== !!isOpen) {
        this.waiterToken = waiter.beginAsync();
      }
      if (isOpen) {
        this.expand(element);
      } else {
        this.contract(element);
      }
    }
    onTransitionEnd(event) {
      if ((event.propertyName === 'height' || event.propertyName == 'opacity') && this.args.isOpen) {
        event.target.style.height = 'auto';
        event.target.style.overflow = '';
      }
      if (this.waiterToken) {
        // when is opened, wait for height transition to finish
        // when is opened, wait for opacity transition to finish at 1
        // when closed, wait for opacity transition to finish at 0
        // when closed and has initialHeight, wait for height transition to finish
        if (this.args.isOpen && event.propertyName === 'height' || !this.args.isOpen && event.propertyName === 'opacity' && event.target.style.opacity == '0' || this.args.isOpen && event.propertyName === 'opacity' && event.target.style.opacity == '1' || !this.args.isOpen && this.args.initialHeight && event.propertyName === 'height') {
          waiter.endAsync(this.waiterToken);
        }
      }
    }
    heightTransition(duration) {
      return "height ".concat(duration, "s cubic-bezier(0.4, 0, 0.2, 1) 0ms");
    }
    opacityTransition(duration) {
      return "opacity ".concat(duration, "s ease-in-out 0ms");
    }
    expand(element) {
      this.isCurrentlyOpen = true;
      element.style.transition = [this.heightTransition(0.4), this.opacityTransition(0.3)].join(', ');
      element.style.overflow = 'hidden';
      const height = element.scrollHeight;
      window.requestAnimationFrame(() => {
        element.style.opacity = '1';
        element.style.height = "".concat(height, "px");
      });
    }
    contract(element) {
      this.isCurrentlyOpen = false;
      const height = element.scrollHeight;
      element.style.transition = '';
      element.style.overflow = 'hidden';
      window.requestAnimationFrame(() => {
        element.style.height = "".concat(height, "px");
        element.style.transition = [this.heightTransition(0.2), this.opacityTransition(0.3)].join(', ');
        window.requestAnimationFrame(() => {
          if (!this.args.initialHeight) {
            element.style.opacity = '0';
          }
          element.style.height = this.args.initialHeight || '0';
        });
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTransitionEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onTransitionEnd"), _class.prototype)), _class);
  _exports.default = Collapsible;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Collapsible);
});