define("ember-window-mock/index", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.reset = reset;
  var _default = window;
  _exports.default = _default;
  function reset() {
    (false && !(true) && (0, _debug.assert)('reset() is only available in tests', true));
  }
});