define("ember-printable-pages/components/printable-pages/section-item", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object/internals", "@ember/object", "ember-concurrency"], function (_exports, _component, _templateFactory, _component2, _internals, _object, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="PrintablePages-sectionItem"
    id={{this.elementId}}
    data-test-section-item
    {{did-insert this.onInsert this}}
    {{did-insert @renderNext this @section}}
    {{will-destroy this.willDestroy}}
    ...attributes
  >
    {{yield}}
  </div>
  */
  {
    "id": "hVEdkLD6",
    "block": "[[[11,0],[24,0,\"PrintablePages-sectionItem\"],[16,1,[30,0,[\"elementId\"]]],[24,\"data-test-section-item\",\"\"],[17,1],[4,[38,0],[[30,0,[\"onInsert\"]],[30,0]],null],[4,[38,0],[[30,2],[30,0],[30,3]],null],[4,[38,1],[[30,0,[\"willDestroy\"]]],null],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@renderNext\",\"@section\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"yield\"]]",
    "moduleName": "ember-printable-pages/components/printable-pages/section-item.hbs",
    "isStrictMode": false
  });
  let SectionItem = (_class = class SectionItem extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "elementId", "ember-" + (0, _internals.guidFor)(this));
      _defineProperty(this, "element", null);
    }
    onInsert(element) {
      this.element = element;
      this.onRender.perform();
      this.args.renderNext();
    }

    // eslint-disable-next-line require-yield
    *onRender() {
      let height = this.element.offsetHeight;
      if (this.args.section.maxItemHeight === null || this.args.section.maxItemHeight < height) {
        this.args.section.maxItemHeight = height;
      }
      if (this.args.section.minItemHeight === null || height < this.args.section.minItemHeight) {
        this.args.section.minItemHeight = height;
      }
      this.args.setLastRenderedItem(this.elementId);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.args.setLastRenderedItem("-" + this.elementId);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRender", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "onRender"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroy"), _class.prototype)), _class);
  _exports.default = SectionItem;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SectionItem);
});