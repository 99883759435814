define("tracked-maps-and-sets/-private/set", ["exports", "ember-tracked-storage-polyfill"], function (_exports, _emberTrackedStoragePolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TrackedWeakSet = _exports.TrackedSet = void 0;
  class TrackedSet {
    constructor(existing) {
      this.collection = (0, _emberTrackedStoragePolyfill.createStorage)(null, () => false);
      this.storages = new Map();
      this.vals = new Set(existing);
    }
    storageFor(key) {
      const storages = this.storages;
      let storage = storages.get(key);
      if (storage === undefined) {
        storage = (0, _emberTrackedStoragePolyfill.createStorage)(null, () => false);
        storages.set(key, storage);
      }
      return storage;
    }
    dirtyStorageFor(key) {
      const storage = this.storages.get(key);
      if (storage) {
        (0, _emberTrackedStoragePolyfill.setValue)(storage, null);
      }
    }
    // **** KEY GETTERS ****
    has(value) {
      (0, _emberTrackedStoragePolyfill.getValue)(this.storageFor(value));
      return this.vals.has(value);
    }
    // **** ALL GETTERS ****
    entries() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals.entries();
    }
    keys() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals.keys();
    }
    values() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals.values();
    }
    forEach(fn) {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      this.vals.forEach(fn);
    }
    get size() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals.size;
    }
    [Symbol.iterator]() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals[Symbol.iterator]();
    }
    get [Symbol.toStringTag]() {
      return this.vals[Symbol.toStringTag];
    }
    // **** KEY SETTERS ****
    add(value) {
      this.dirtyStorageFor(value);
      (0, _emberTrackedStoragePolyfill.setValue)(this.collection, null);
      this.vals.add(value);
      return this;
    }
    delete(value) {
      this.dirtyStorageFor(value);
      (0, _emberTrackedStoragePolyfill.setValue)(this.collection, null);
      return this.vals.delete(value);
    }
    // **** ALL SETTERS ****
    clear() {
      this.storages.forEach(s => (0, _emberTrackedStoragePolyfill.setValue)(s, null));
      (0, _emberTrackedStoragePolyfill.setValue)(this.collection, null);
      this.vals.clear();
    }
  }
  // So instanceof works
  _exports.TrackedSet = TrackedSet;
  Object.setPrototypeOf(TrackedSet.prototype, Set.prototype);
  class TrackedWeakSet {
    constructor(values) {
      this.storages = new WeakMap();
      this.vals = new WeakSet(values);
    }
    storageFor(key) {
      const storages = this.storages;
      let storage = storages.get(key);
      if (storage === undefined) {
        storage = (0, _emberTrackedStoragePolyfill.createStorage)(null, () => false);
        storages.set(key, storage);
      }
      return storage;
    }
    dirtyStorageFor(key) {
      const storage = this.storages.get(key);
      if (storage) {
        (0, _emberTrackedStoragePolyfill.setValue)(storage, null);
      }
    }
    has(value) {
      (0, _emberTrackedStoragePolyfill.getValue)(this.storageFor(value));
      return this.vals.has(value);
    }
    add(value) {
      // Add to vals first to get better error message
      this.vals.add(value);
      this.dirtyStorageFor(value);
      return this;
    }
    delete(value) {
      this.dirtyStorageFor(value);
      return this.vals.delete(value);
    }
    get [Symbol.toStringTag]() {
      return this.vals[Symbol.toStringTag];
    }
  }
  // So instanceof works
  _exports.TrackedWeakSet = TrackedWeakSet;
  Object.setPrototypeOf(TrackedWeakSet.prototype, WeakSet.prototype);
});