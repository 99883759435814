define("@frontile/forms/components/form-field/textarea", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <textarea
    {{on "input" this.handleOnInput}}
    {{on "change" this.handleOnChange}}
    id={{@id}}
    value={{@value}}
    class={{use-frontile-class "form-field-textarea" @size}}
    data-test-id="form-field-textarea"
    ...attributes
  >
  </textarea>
  
  */
  {
    "id": "QLLuh4F/",
    "block": "[[[11,\"textarea\"],[16,1,[30,1]],[16,2,[30,2]],[16,0,[28,[37,0],[\"form-field-textarea\",[30,3]],null]],[17,4],[4,[38,1],[\"input\",[30,0,[\"handleOnInput\"]]],null],[4,[38,1],[\"change\",[30,0,[\"handleOnChange\"]]],null],[12],[1,\"\"],[13],[1,\"\\n\"]],[\"@id\",\"@value\",\"@size\",\"&attrs\"],false,[\"use-frontile-class\",\"on\"]]",
    "moduleName": "@frontile/forms/components/form-field/textarea.hbs",
    "isStrictMode": false
  });
  let FormFieldTextarea = (_class = class FormFieldTextarea extends _component2.default {
    handleOnInput(event) {
      if (typeof this.args.onInput === 'function') {
        this.args.onInput(event.target.value, event);
      }
    }
    handleOnChange(event) {
      if (typeof this.args.onChange === 'function') {
        this.args.onChange(event.target.value, event);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleOnInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOnInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleOnChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOnChange"), _class.prototype)), _class);
  _exports.default = FormFieldTextarea;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormFieldTextarea);
});