define("@ember-data/tracking/index", ["exports", "@ember-data/tracking/-private"], function (_exports, _private) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "memoTransact", {
    enumerable: true,
    get: function get() {
      return _private.memoTransact;
    }
  });
  Object.defineProperty(_exports, "transact", {
    enumerable: true,
    get: function get() {
      return _private.transact;
    }
  });
  Object.defineProperty(_exports, "untracked", {
    enumerable: true,
    get: function get() {
      return _private.untracked;
    }
  });
});