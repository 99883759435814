define("ember-svg-jar/inlined/chevron-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.648 6.852l-5.797 5.797q-.148.148-.352.148t-.352-.148L.85 11.352Q.702 11.204.702 11t.148-.352L4.998 6.5.85 2.352Q.702 2.204.702 2t.148-.352L2.147.351q.148-.148.352-.148t.352.148l5.797 5.797q.148.148.148.352t-.148.352z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "9",
      "height": "14",
      "viewBox": "0 0 9 14"
    }
  };
  _exports.default = _default;
});