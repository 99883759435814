define("ember-svg-jar/inlined/record-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M90.4 181.3C40.7 181.3.2 140.8.2 91.1S40.7.9 90.4.9s90.2 40.5 90.2 90.2-40.5 90.2-90.2 90.2zm0-168.4c-43.1 0-78.2 35.1-78.2 78.2s35.1 78.2 78.2 78.2 78.2-35.1 78.2-78.2-35.1-78.2-78.2-78.2z\" fill=\"#000\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "181",
      "height": "182",
      "viewBox": "0 0 181 182",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});