define("ember-math-helpers/helpers/tanh", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.tanh = tanh;
  /**
   * Executes `Math.tanh` on the number passed to the helper.
   *
   * ```hbs
   * {{tanh a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.tanh`
   * @return {number} The tanh of the passed number
   */
  function tanh(_ref) {
    let [number] = _ref;
    return Math.tanh(number);
  }
  var _default = (0, _helper.helper)(tanh);
  _exports.default = _default;
});