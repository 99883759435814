define("ember-local-storage/index", ["exports", "ember-local-storage/helpers/storage"], function (_exports, _storage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "storageFor", {
    enumerable: true,
    get: function get() {
      return _storage.storageFor;
    }
  });
});