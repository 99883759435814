define("ember-container-query/modifiers/container-query", ["exports", "@ember/destroyable", "@ember/object", "@ember/runloop", "@ember/service", "ember-modifier"], function (_exports, _destroyable, _object, _runloop, _service, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ContainerQueryModifier = (_class = class ContainerQueryModifier extends _emberModifier.default {
    get dataAttributePrefix() {
      var _this$_named$dataAttr;
      return (_this$_named$dataAttr = this._named.dataAttributePrefix) !== null && _this$_named$dataAttr !== void 0 ? _this$_named$dataAttr : 'container-query';
    }
    get debounce() {
      var _this$_named$debounce;
      return (_this$_named$debounce = this._named.debounce) !== null && _this$_named$debounce !== void 0 ? _this$_named$debounce : 0;
    }
    get features() {
      var _this$_named$features;
      return (_this$_named$features = this._named.features) !== null && _this$_named$features !== void 0 ? _this$_named$features : {};
    }
    constructor(owner, args) {
      super(owner, args);
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore */
      _initializerDefineProperty(this, "resizeObserver", _descriptor, this);
      _defineProperty(this, "dimensions", void 0);
      _defineProperty(this, "queryResults", void 0);
      _defineProperty(this, "_dataAttributes", []);
      _defineProperty(this, "_element", void 0);
      _defineProperty(this, "_named", void 0);
      (0, _destroyable.registerDestructor)(this, () => {
        this.resizeObserver.unobserve(this._element, this.onResize);
      });
    }
    modify(element, _positional, named) {
      this._named = named;
      this.registerResizeObserver(element);
      this.queryContainer(element);
    }
    onResize(resizeObserverEntry) {
      const element = resizeObserverEntry.target;
      if (this.debounce > 0) {
        (0, _runloop.debounce)(this, this.queryContainer, element, this.debounce);
        return;
      }
      this.queryContainer(element);
    }
    registerResizeObserver(element) {
      this.resizeObserver.unobserve(this._element, this.onResize);
      this._element = element;
      this.resizeObserver.observe(this._element, this.onResize);
    }
    queryContainer(element) {
      var _this$_named$onQuery, _this$_named;
      this.measureDimensions(element);
      this.evaluateQueries();
      this.resetDataAttributes(element);
      this.setDataAttributes(element);
      (_this$_named$onQuery = (_this$_named = this._named).onQuery) === null || _this$_named$onQuery === void 0 ? void 0 : _this$_named$onQuery.call(_this$_named, {
        dimensions: this.dimensions,
        queryResults: this.queryResults
      });
    }
    measureDimensions(element) {
      const height = element.clientHeight;
      const width = element.clientWidth;
      this.dimensions = {
        aspectRatio: width / height,
        height,
        width
      };
    }
    evaluateQueries() {
      const queryResults = {};
      for (const [featureName, metadata] of Object.entries(this.features)) {
        const {
          dimension,
          min,
          max
        } = metadata;
        const value = this.dimensions[dimension];
        queryResults[featureName] = min <= value && value < max;
      }
      this.queryResults = queryResults;
    }
    resetDataAttributes(element) {
      this._dataAttributes.forEach(dataAttribute => {
        element.removeAttribute(dataAttribute);
      });
      this._dataAttributes = [];
    }
    setDataAttributes(element) {
      const prefix = this.dataAttributePrefix;
      for (const [featureName, meetsFeature] of Object.entries(this.queryResults)) {
        if (!meetsFeature) {
          continue;
        }
        const dataAttribute = prefix ? "data-".concat(prefix, "-").concat(featureName) : "data-".concat(featureName);
        element.setAttribute(dataAttribute, '');
        this._dataAttributes.push(dataAttribute);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "resizeObserver", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onResize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onResize"), _class.prototype)), _class);
  _exports.default = ContainerQueryModifier;
});