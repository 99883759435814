define("ember-modal-dialog/instance-initializers/add-modals-container", ["exports", "ember-modal-dialog/utils/config-utils", "@ember/application"], function (_exports, _configUtils, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  let hasDOM = typeof document !== 'undefined';
  function appendContainerElement(rootElementOrId, id) {
    if (!hasDOM) {
      return;
    }
    let rootEl = rootElementOrId.appendChild ? rootElementOrId : document.querySelector(rootElementOrId);
    if (rootEl.querySelector('#' + id)) {
      return;
    }
    let modalContainerEl = document.createElement('div');
    modalContainerEl.id = id;
    rootEl.appendChild(modalContainerEl);
  }
  function _default(instance) {
    let config = instance.resolveRegistration('config:environment');
    let modalContainerElId = (0, _configUtils.getDestinationElementIdFromConfig)(config);

    // As there is only a single `Router` across the whole app, which is owned
    // by the root `Application`, this reliably finds the root `Application`
    // from an `Application` or `Engine`.
    // eslint-disable-next-line ember/no-private-routing-service
    let app = (0, _application.getOwner)(instance.lookup('router:main'));
    appendContainerElement(app.rootElement, modalContainerElId);
  }
});