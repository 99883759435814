define("ember-can/ability", ["exports", "@ember/object", "@ember/string"], function (_exports, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EmberObjectAbility extends _object.default {
    /**
     * Parse propertyName into ability property
     * eg: `createProject` will be parsed to `canCreateProject` using default definition
     * @public
     * @param  {[String]} propertyName [description]
     * @return {[String]}              [description]
     */
    parseProperty(propertyName) {
      return (0, _string.camelize)("can-".concat(propertyName));
    }

    /**
     * Get parsed ability value based on propertyName
     * eg: `createProject` will return a value for `canCreateProject`
     * using default `parseProperty` definition
     * @param  {String} propertyName property name, eg. `createProject`
     * @param  {any} model
     * @param  {Object} properties
     * @return {*}                   value of parsed `propertyName` property
     */
    getAbility(propertyName, model, properties) {
      const abilityValue = (0, _object.get)(this, this.parseProperty(propertyName));
      if (typeof abilityValue === 'function') {
        return abilityValue.call(this, model, properties);
      }
      return abilityValue;
    }
  }
  _exports.default = EmberObjectAbility;
});