define("ember-math-helpers/helpers/cbrt", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cbrt = cbrt;
  _exports.default = void 0;
  /**
   * Executes `Math.cbrt` on the number passed to the helper.
   *
   * ```hbs
   * {{cbrt a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.cbrt`
   * @return {number} The cbrt of the passed number
   */
  function cbrt(_ref) {
    let [number] = _ref;
    return Math.cbrt(number);
  }
  var _default = (0, _helper.helper)(cbrt);
  _exports.default = _default;
});