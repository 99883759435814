define("@frontile/forms/components/form-field/hint", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    id={{@id}}
    class={{use-frontile-class "form-field-hint" @size}}
    data-test-id="form-field-hint"
    ...attributes
  >
    {{yield}}
  </div>
  
  */
  {
    "id": "w9Wj3Pfa",
    "block": "[[[11,0],[16,1,[30,1]],[16,0,[28,[37,0],[\"form-field-hint\",[30,2]],null]],[17,3],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@id\",\"@size\",\"&attrs\",\"&default\"],false,[\"use-frontile-class\",\"yield\"]]",
    "moduleName": "@frontile/forms/components/form-field/hint.hbs",
    "isStrictMode": false
  });
  class FormFieldHint extends _component2.default {}
  _exports.default = FormFieldHint;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormFieldHint);
});