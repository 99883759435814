define("@algonauti/ember-active-storage/-private/request", ["exports", "@ember/utils", "rsvp"], function (_exports, _utils, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(xhr, url, options) {
    return new _rsvp.Promise((resolve, reject) => {
      xhr.open(options.method || 'GET', url);
      if (options.headers) {
        Object.keys(options.headers).forEach(key => {
          xhr.setRequestHeader(key, options.headers[key]);
        });
      }
      if (options.contentType) {
        xhr.setRequestHeader('Content-Type', options.contentType);
      }
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          let response;
          if (options.dataType === 'text') {
            response = xhr.responseText;
          } else if ((0, _utils.isPresent)(xhr.responseText)) {
            response = JSON.parse(xhr.responseText);
          }
          resolve(response);
        } else {
          reject(xhr.statusText);
        }
      };
      xhr.onabort = () => reject(xhr.statusText);
      xhr.onerror = () => reject(xhr.statusText);
      xhr.send(options.data);
      xhr.dispatchEvent(new CustomEvent('XHROpened', {
        detail: xhr
      }));
    });
  }
});