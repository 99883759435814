define("ember-modal-dialog/utils/config-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDestinationElementIdFromConfig = getDestinationElementIdFromConfig;
  function getDestinationElementIdFromConfig(config) {
    // if (config.environment === 'test') {
    //   return 'ember-testing';
    // }
    let modalContainerId = config['ember-modal-dialog'] && config['ember-modal-dialog'].modalRootElementId;
    modalContainerId = modalContainerId || 'modal-overlays';
    return modalContainerId;
  }
});