define("ember-math-helpers/helpers/log10", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.log10 = log10;
  /**
   * Executes `Math.log10` on the number passed to the helper.
   *
   * ```hbs
   * {{log10 a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.log10`
   * @return {number} The log10 of the passed number
   */
  function log10(_ref) {
    let [number] = _ref;
    return Math.log10(number);
  }
  var _default = (0, _helper.helper)(log10);
  _exports.default = _default;
});