define("ember-printable-pages/components/printable-pages/chapter-page", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/utils", "@ember/object", "@ember/object/internals", "@glimmer/tracking", "ember-concurrency", "ember-printable-pages/utils/logger"], function (_exports, _component, _templateFactory, _component2, _service, _utils, _object, _internals, _tracking, _emberConcurrency, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="PrintablePages-chapterPage"
    id={{this.elementId}}
    {{page-renderer}}
    {{did-insert this.onInsert this}}
    {{did-update
      this.onUpdate
      this.previousLastRenderedItemId
      @lastRenderedItemId
    }}
    {{will-destroy this.stopHeightChangeObserver}}
  >
    {{yield
      (hash meta=@pageMeta renderNext=(perform this.renderNext))
      to="header"
    }}
  
    <div class="PrintablePages-pageBody js-page-body">
      <div class="js-page-body-inner">
        {{yield
          (hash
            element=this.element
            meta=@pageMeta
            registerSection=this.registerSection
            setLastRenderedItem=(perform this.setLastRenderedItem)
            renderNext=(perform this.renderNext)
          )
          to="body"
        }}
      </div>
  
      {{! element used to check if the content has overflowed page body}}
      <div class="js-visibility-tail" {{style height="1px"}}></div>
    </div>
  
    {{yield (hash meta=@pageMeta) to="footer"}}
    <div class="js-page-break-after u-page-break-after"></div>
  </div>
  
  */
  {
    "id": "6UPgc+RI",
    "block": "[[[11,0],[24,0,\"PrintablePages-chapterPage\"],[16,1,[30,0,[\"elementId\"]]],[4,[38,0],null,null],[4,[38,1],[[30,0,[\"onInsert\"]],[30,0]],null],[4,[38,2],[[30,0,[\"onUpdate\"]],[30,0,[\"previousLastRenderedItemId\"]],[30,1]],null],[4,[38,3],[[30,0,[\"stopHeightChangeObserver\"]]],null],[12],[1,\"\\n  \"],[18,3,[[28,[37,5],null,[[\"meta\",\"renderNext\"],[[30,2],[28,[37,6],[[30,0,[\"renderNext\"]]],null]]]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"PrintablePages-pageBody js-page-body\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"js-page-body-inner\"],[12],[1,\"\\n      \"],[18,4,[[28,[37,5],null,[[\"element\",\"meta\",\"registerSection\",\"setLastRenderedItem\",\"renderNext\"],[[30,0,[\"element\"]],[30,2],[30,0,[\"registerSection\"]],[28,[37,6],[[30,0,[\"setLastRenderedItem\"]]],null],[28,[37,6],[[30,0,[\"renderNext\"]]],null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[1,\"    \"],[11,0],[24,0,\"js-visibility-tail\"],[4,[38,7],null,[[\"height\"],[\"1px\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[18,5,[[28,[37,5],null,[[\"meta\"],[[30,2]]]]]],[1,\"\\n  \"],[10,0],[14,0,\"js-page-break-after u-page-break-after\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@lastRenderedItemId\",\"@pageMeta\",\"&header\",\"&body\",\"&footer\"],false,[\"page-renderer\",\"did-insert\",\"did-update\",\"will-destroy\",\"yield\",\"hash\",\"perform\",\"style\"]]",
    "moduleName": "ember-printable-pages/components/printable-pages/chapter-page.hbs",
    "isStrictMode": false
  });
  let ChapterPage = (_dec = (0, _emberConcurrency.task)({
    keepLatest: true
  }), _dec2 = (0, _emberConcurrency.task)({
    drop: true
  }), (_class = class ChapterPage extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "elementId", "ember-" + (0, _internals.guidFor)(this));
      _initializerDefineProperty(this, "element", _descriptor, this);
      _initializerDefineProperty(this, "lastRenderedItemId", _descriptor2, this);
      _initializerDefineProperty(this, "documentData", _descriptor3, this);
      // INTERNAL STATE
      _defineProperty(this, "sectionRegistrationIndex", 0);
    }
    // Register the section if this page is the first in the chapter.
    // Return the section index in the page as the section's id. This
    // is the unique id for the section across all pages.
    registerSection(data) {
      let id = this.sectionRegistrationIndex;
      if (this.args.pageIndexInChapter === 0) {
        this.args.registerSection(id, data);
      }
      this.sectionRegistrationIndex = this.sectionRegistrationIndex + 1;
      return id;
    }
    onInsert(element) {
      this.element = element;
      (0, _logger.log)("<chapter-page:".concat(this.elementId, "> on-insert"));
      this.renderNext.perform();
    }
    onUpdate() {
      (0, _logger.log)("<chapter-page:".concat(this.elementId, "> did-update"));
      this.renderNext.perform();
    }

    // eslint-disable-next-line require-yield
    *setLastRenderedItem(elementId) {
      (0, _logger.log)("<chapter-page:".concat(this.elementId, "> setLastRenderedItem"));
      this.lastRenderedItemId = elementId;
      this.renderNext.perform();
    }
    *waitForFixedBody() {
      yield (0, _emberConcurrency.waitForProperty)(this, "element");
      yield (0, _emberConcurrency.waitForProperty)(this, "pageBodyElement", b => {
        var _b$style;
        return b === null || b === void 0 ? void 0 : (_b$style = b.style) === null || _b$style === void 0 ? void 0 : _b$style.height;
      });
    }
    get pageElement() {
      return this.element;
    }
    get pageBodyElement() {
      return this.element.querySelector(".js-page-body");
    }
    get visibilityTailElement() {
      return this.element.querySelector(".js-visibility-tail");
    }
    get pageBreakElement() {
      return this.element.querySelector(".js-page-break-after");
    }
    get innerPageBodyElement() {
      return this.element.querySelector(".js-page-body-inner");
    }
    startHeightChangeObserver() {
      if (this._heightChangeObserver) return;
      this._heightChangeObserver = new ResizeObserver(this.renderNext.perform);
      this._heightChangeObserver.observe(this.innerPageBodyElement);
    }
    stopHeightChangeObserver() {
      if (!this._heightChangeObserver) return;
      this._heightChangeObserver.unobserve(this.innerPageBodyElement);
    }
    *renderNext() {
      // The first rendering of chapter-page should only have header and footer content.
      // modifier:page-renderer will set a fixed height on this element.
      //
      // Upon completion ask for items to be added to the page.
      yield this.waitForFixedBody.perform();
      this.startHeightChangeObserver();

      // This allows routes to finish transitioning while the printable pages doc is still rendering.
      yield (0, _emberConcurrency.timeout)(0);

      // This component determines whether it needs more items,
      // or fewer based upon where the `.js-visibility-tail` is
      // located in the dom relative to the `.js-page-body` element.
      let tailBounding = this.visibilityTailElement.getBoundingClientRect();
      // Grab the bounding rect for the `.js-page-body` element
      let pageBounding = this.pageBodyElement.getBoundingClientRect();
      let tailPosition = Math.floor(pageBounding.bottom) - Math.ceil(tailBounding.bottom);
      (0, _logger.log)("<chapter-page:".concat(this.elementId, "> renderNext -- tail position"), "".concat(tailPosition, "px"));

      // If the tail hasn't moved, then do nothing.
      // This can happen if the page count increments in a
      // separate render from adding/removing section items.
      //
      // Consider 'movement' to be more than 2px. This feels brittle
      // and may need to be revised.
      if ((0, _utils.isPresent)(this.previousTailPosition)) {
        let tailMovement = Math.abs(this.previousTailPosition - tailPosition);
        if (tailMovement <= 2 && this.previousLastRenderedItemId === this.lastRenderedItemId) {
          return;
        }
      }

      // Determine if the page has overflowed.
      let hasOverflow = tailPosition < 0;

      // NOTE One side effect of the conditionals below is that once
      // a page thinks it is full, it will never ask for another item.
      // This means if items change size (shrink) once rendered then
      // extra whitespace will be left at the bottom of hte page. If
      // items grow, and overflow the page, the page _will_ ask for
      // an item to be removed.
      if (hasOverflow) {
        // If the page overflowed...
        // call onPageOverflow so the parent context can remove an item
        this.overflowed = true;
        this.args.onPageOverflow();
      } else if (!this.overflowed) {
        // If the page did not overflow this time AND it has never overflowed...
        // tell the context this page can handle more item(s)
        if (this.isDestroyed) return;
        this.args.renderNextItem(tailPosition);
      } else if (!this.isSettled) {
        // did not overflow this time, but did in the past...
        // then the page is probably settled. let context know
        // it can render the next page if it wants

        this.isSettled = true;
        this.args.renderNextPage();
      }
      this.previousTailPosition = tailPosition;
      this.previousLastRenderedItemId = this.lastRenderedItemId;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "element", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "lastRenderedItemId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "documentData", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "registerSection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerSection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setLastRenderedItem", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setLastRenderedItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "waitForFixedBody", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "waitForFixedBody"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stopHeightChangeObserver", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "stopHeightChangeObserver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "renderNext", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "renderNext"), _class.prototype)), _class));
  _exports.default = ChapterPage;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChapterPage);
});