define("ember-container-query/helpers/width", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const WidthHelper = (0, _helper.helper)((_positional, named) => {
    var _named$max, _named$min;
    const dimension = 'width';
    const max = (_named$max = named.max) !== null && _named$max !== void 0 ? _named$max : Infinity;
    const min = (_named$min = named.min) !== null && _named$min !== void 0 ? _named$min : 0;
    return {
      dimension,
      max,
      min
    };
  });
  var _default = WidthHelper;
  _exports.default = _default;
});