define("ember-api-actions/utils/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.strictifyHttpVerb = strictifyHttpVerb;
  function strictifyHttpVerb(notStrict) {
    return "".concat(notStrict).toUpperCase();
  }
});