define("ember-svg-jar/inlined/check-square-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M400 0H48C21.49 0 0 21.49 0 48v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zm0 32c8.823 0 16 7.178 16 16v352c0 8.822-7.177 16-16 16H48c-8.822 0-16-7.178-16-16V48c0-8.822 7.178-16 16-16h352\" fill=\"#000\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "448",
      "height": "448",
      "viewBox": "0 0 448 448",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});