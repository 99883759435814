define("ember-printable-pages/util-models/chapter", ["exports", "@glimmer/tracking", "@ember/object", "ember-printable-pages/utils/logger"], function (_exports, _tracking, _object, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let Chapter = (_class = class Chapter {
    constructor(_ref) {
      let {
        id,
        index,
        startPage,
        endPage,
        name,
        isToc
      } = _ref;
      _defineProperty(this, "sectionMap", {});
      _defineProperty(this, "sections", []);
      _initializerDefineProperty(this, "pages", _descriptor, this);
      _initializerDefineProperty(this, "endPage", _descriptor2, this);
      _initializerDefineProperty(this, "startPage", _descriptor3, this);
      this.id = id;
      this.index = index;
      this.startPage = startPage;
      this.endPage = endPage;
      this.name = name;
      this.isToc = isToc;
    }
    get sectionCount() {
      var _this$sections;
      return ((_this$sections = this.sections) === null || _this$sections === void 0 ? void 0 : _this$sections.length) || 0;
    }
    get isFinishedRendering() {
      return !this.sections.find(s => !s.isFullyRendered);
    }
    instrument() {
      (0, _logger.group)("sections");
      this.sections.map(s => (0, _logger.log)(s.toString(), s.pages.map(p => p === null || p === void 0 ? void 0 : p.toJson())));
      (0, _logger.groupEnd)("sections");
    }
    renderNextItem(pageIndex, remainingHeight) {
      (0, _logger.group)(this.logPrefix(), "#renderNextItem( pageIndex: ".concat(pageIndex, ", remainingHeight: ").concat(remainingHeight, ")"));
      this.instrument();
      let section = this.sections.find(s => s.isFullyRendered == false);

      // If no section, then this chapter is done!
      if (this.isFinishedRendering) {
        this.log("isFinishedRendering");
        (0, _logger.groupEnd)(this.logPrefix(), "#renderNextItem(".concat(pageIndex, ", ").concat(remainingHeight, ")"));
        return;
      }
      let didAddNewPage = false;
      if (!section.pages.at(pageIndex)) {
        didAddNewPage = true;
        section.addPage(pageIndex, section.nextItemIndex);
        section.nextItemIndex = section.nextItemIndex + 1;
      }
      let page = section.pages.at(pageIndex);

      // If rendered 2 or more items AND similar in height (within 200px)
      if (section.nextItemIndex > 1 && section.itemHeightDiff < 200) {
        let remainingItemCount = section.data.length - section.nextItemIndex;
        let heightGuess = (section.maxItemHeight + section.minItemHeight) / 2;
        let fastForwardCount = Math.round(section.columnCount * remainingHeight / heightGuess);
        fastForwardCount = Math.max(1, fastForwardCount);
        fastForwardCount = Math.min(fastForwardCount, remainingItemCount);
        page.endIndex = page.endIndex + fastForwardCount;
        section.nextItemIndex = section.nextItemIndex + fastForwardCount;
      } else if (!didAddNewPage) {
        // ELSE increment forward by 1
        page.endIndex = section.nextItemIndex;
        section.nextItemIndex = section.nextItemIndex + 1;
      }
      section.updateIsFullyRendered();
      this.instrument();
      (0, _logger.groupEnd)(this.logPrefix(), "#renderNextItem( pageIndex: ".concat(pageIndex, ", remainingHeight: ").concat(remainingHeight, ")"));
    }
    lastSectionInPage(pageIndex) {
      // Find sections with data in page at pageIndex
      let sectionsInPage = this.sections.filter(section => !!section.pages.at(pageIndex));
      return sectionsInPage[sectionsInPage.length - 1];
    }
    removeItemFromPage(pageIndex) {
      (0, _logger.group)(this.logPrefix(), "#removeItemFromPage(".concat(pageIndex, ")"));
      this.instrument();
      let section = this.lastSectionInPage(pageIndex);
      let pageInSection = section.pages.at(pageIndex);

      // Take an item away from the current page
      if (pageInSection.endIndex === 0) {
        let pagesClone = [...section.pages];
        pagesClone.splice(pageIndex, 1);
        pagesClone.splice(pageIndex, 0, null);
        section.pages = pagesClone;
      } else {
        pageInSection.endIndex = pageInSection.endIndex - 1;
      }
      section.nextItemIndex = section.nextItemIndex - 1;
      section.isFullyRendered = false;
      this.instrument();
      (0, _logger.groupEnd)(this.logPrefix(), "#removeItemFromPage(".concat(pageIndex, ")"));
    }
    moveLastItem(pageIndex, addPageFn) {
      (0, _logger.group)(this.logPrefix(), "#moveLastItem(".concat(pageIndex, ", fn)"));
      this.instrument();

      // If there is only one item on the page, don't move it
      if (this.lastSectionDidNotFit(pageIndex)) {
        (0, _logger.log)(this.logPrefix(), "#moveLastItem(".concat(pageIndex, ", fn) -- lastSectionDidNotFit"));
        if (!this.isFinishedRendering) addPageFn(this.id);
        return;
      }

      // Remove an item from the page
      this.removeItemFromPage(pageIndex);

      // If the next page exists, move item to that page.
      // Else add a page
      if (this.pages.at(pageIndex + 1)) {
        (0, _logger.log)(this.logPrefix(), "#moveLastItem(".concat(pageIndex, ", fn) -- move to next page"));
        let lastSectionInPage = this.lastSectionInPage(pageIndex);
        lastSectionInPage.reconcilePageStartIndex(pageIndex + 1);
      }
      this.instrument();
      (0, _logger.groupEnd)(this.logPrefix(), "#moveLastItem(".concat(pageIndex, ", fn)"));
    }
    lastSectionDidNotFit(pageIndex) {
      let itemCountForPage = this.itemCountForPage(pageIndex);
      if (itemCountForPage === 1) {
        // eslint-disable-next-line no-console
        console.warn("ember-printable-pages could not fit a section item within a full page. " + "Content is likely clipped or page/column breaks are in unexpected places. " + "See page ".concat(pageIndex + 1, "."));
        return true;
      }
      return false;
    }
    itemCountForPage(pageIndex) {
      return this.sections.reduce((a, v) => a + v.itemCountForPage(pageIndex), 0);
    }
    log() {
      (0, _logger.log)(this.logPrefix(), ...arguments);
    }
    logPrefix() {
      return "<util-models:chapter:".concat(this.id, ":index-").concat(this.index, ">");
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "pages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "endPage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "startPage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderNextItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "renderNextItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "lastSectionInPage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "lastSectionInPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeItemFromPage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeItemFromPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "moveLastItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "moveLastItem"), _class.prototype)), _class);
  _exports.default = Chapter;
});