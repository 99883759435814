define("@videoly/ember-trix-next/components/trix-editor/editor", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <input id="{{@inputId}}" value="{{@initialValue}}" type="hidden" data-test-trix-input />
  <trix-editor
    data-test-trix-editor
    toolbar="{{@toolbarId}}"
    input="{{@inputId}}"
    {{on 'trix-initialize' (or @trixInitialize @noop)}}
    {{on 'trix-change' (or @trixChange @noop)}}
    {{on 'trix-paste' (or @trixPaste @noop)}}
    {{on 'trix-selection-change' (or @trixSelectionChange @noop)}}
    {{on 'trix-focus' (or @trixFocus @noop)}}
    {{on 'trix-blur' (or @trixBlur @noop)}}
    {{on 'trix-file-accept' (or @trixFileAccept @noop)}}
    {{on 'trix-attachment-add' (or @trixAttachmentAdd @noop)}}
    {{on 'trix-attachment-remove' (or @trixAttachmentRemove @noop)}}
    ...attributes
  />
  
  {{yield}}
  
  */
  {
    "id": "6OCvIHMg",
    "block": "[[[10,\"input\"],[15,1,[29,[[30,1]]]],[15,2,[29,[[30,2]]]],[14,\"data-test-trix-input\",\"\"],[14,4,\"hidden\"],[12],[13],[1,\"\\n\"],[11,\"trix-editor\"],[24,\"data-test-trix-editor\",\"\"],[16,\"toolbar\",[29,[[30,3]]]],[16,\"input\",[29,[[30,1]]]],[17,4],[4,[38,0],[\"trix-initialize\",[28,[37,1],[[30,5],[30,6]],null]],null],[4,[38,0],[\"trix-change\",[28,[37,1],[[30,7],[30,6]],null]],null],[4,[38,0],[\"trix-paste\",[28,[37,1],[[30,8],[30,6]],null]],null],[4,[38,0],[\"trix-selection-change\",[28,[37,1],[[30,9],[30,6]],null]],null],[4,[38,0],[\"trix-focus\",[28,[37,1],[[30,10],[30,6]],null]],null],[4,[38,0],[\"trix-blur\",[28,[37,1],[[30,11],[30,6]],null]],null],[4,[38,0],[\"trix-file-accept\",[28,[37,1],[[30,12],[30,6]],null]],null],[4,[38,0],[\"trix-attachment-add\",[28,[37,1],[[30,13],[30,6]],null]],null],[4,[38,0],[\"trix-attachment-remove\",[28,[37,1],[[30,14],[30,6]],null]],null],[12],[13],[1,\"\\n\\n\"],[18,15,null],[1,\"\\n\"]],[\"@inputId\",\"@initialValue\",\"@toolbarId\",\"&attrs\",\"@trixInitialize\",\"@noop\",\"@trixChange\",\"@trixPaste\",\"@trixSelectionChange\",\"@trixFocus\",\"@trixBlur\",\"@trixFileAccept\",\"@trixAttachmentAdd\",\"@trixAttachmentRemove\",\"&default\"],false,[\"on\",\"or\",\"yield\"]]",
    "moduleName": "@videoly/ember-trix-next/components/trix-editor/editor.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});