define("ember-svg-jar/inlined/arrow-alt-down-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M71 .059v243.882H24.941c-21.382 0-32.09 25.851-16.971 40.971l86.059 86.059c9.373 9.373 24.568 9.373 33.941 0l86.059-86.059c15.119-15.119 4.411-40.971-16.971-40.971H151V.059H71z\" fill=\"#000\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "222",
      "height": "378",
      "viewBox": "0 0 222 378",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});