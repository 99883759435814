define("ember-concurrency/-private/external/scheduler/policies/enqueued-policy", ["exports", "ember-concurrency/-private/external/scheduler/policies/bounded-policy", "ember-concurrency/-private/external/scheduler/policies/execution-states"], function (_exports, _boundedPolicy, _executionStates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EnqueuedReducer {
    constructor(remainingSlots) {
      this.remainingSlots = remainingSlots;
    }
    step() {
      if (this.remainingSlots > 0) {
        this.remainingSlots--;
        return _executionStates.STARTED;
      } else {
        return _executionStates.QUEUED;
      }
    }
  }
  class EnqueuedPolicy extends _boundedPolicy.default {
    makeReducer() {
      return new EnqueuedReducer(this.maxConcurrency);
    }
  }
  var _default = EnqueuedPolicy;
  _exports.default = _default;
});