define("@frontile/changeset-form/components/changeset-form/fields/checkbox-group", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/debug", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FormCheckboxGroup
    @onChange={{this.handleChange}}
    @errors={{this.errors}}
  
    @label={{@label}}
    @hint={{@hint}}
    @hasError={{@hasError}}
    @showError={{@showError}}
    @hasSubmitted={{@hasSubmitted}}
    @containerClass={{@containerClass}}
    @size={{@size}}
    @isInline={{@isInline}}
  
    ...attributes
    as |Checkbox api|
  >
    {{yield (component "changeset-form/fields/checkbox"
              _groupName=@groupName
              _parentOnChange=api.onChange
              changeset=@changeset
              size=@size
            )
    }}
  </FormCheckboxGroup>
  
  */
  {
    "id": "TFXkKGVq",
    "block": "[[[8,[39,0],[[17,1]],[[\"@onChange\",\"@errors\",\"@label\",\"@hint\",\"@hasError\",\"@showError\",\"@hasSubmitted\",\"@containerClass\",\"@size\",\"@isInline\"],[[30,0,[\"handleChange\"]],[30,0,[\"errors\"]],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9]]],[[\"default\"],[[[[1,\"\\n  \"],[18,14,[[50,\"changeset-form/fields/checkbox\",0,null,[[\"_groupName\",\"_parentOnChange\",\"changeset\",\"size\"],[[30,12],[30,11,[\"onChange\"]],[30,13],[30,8]]]]]],[1,\"\\n\"]],[10,11]]]]],[1,\"\\n\"]],[\"&attrs\",\"@label\",\"@hint\",\"@hasError\",\"@showError\",\"@hasSubmitted\",\"@containerClass\",\"@size\",\"@isInline\",\"Checkbox\",\"api\",\"@groupName\",\"@changeset\",\"&default\"],false,[\"form-checkbox-group\",\"yield\",\"component\"]]",
    "moduleName": "@frontile/changeset-form/components/changeset-form/fields/checkbox-group.hbs",
    "isStrictMode": false
  });
  let ChangesetFormFieldsCheckboxGroup = (_class = class ChangesetFormFieldsCheckboxGroup extends _component2.default {
    constructor(owner, args) {
      super(owner, args);
      (false && !(typeof this.args.changeset !== 'undefined') && (0, _debug.assert)('<ChangesetForm> fields must receive @changeset', typeof this.args.changeset !== 'undefined'));
    }
    get errors() {
      if (typeof this.args.errors !== 'undefined') {
        return this.args.errors;
      }
      if (!this.args.groupName) {
        return [];
      }
      const fieldErrors = this.args.changeset.errors.filter(error => {
        return error.key === this.args.groupName;
      });
      return fieldErrors.reduce((errors, error) => {
        return [...errors, ...error.validation];
      }, []);
    }
    async validate() {
      if (this.args.groupName) {
        await this.args.changeset.validate(this.args.groupName);
      }
    }
    handleChange(value, event) {
      this.validate();
      if (typeof this.args.onChange === 'function') {
        this.args.onChange(value, event);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "validate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype)), _class);
  _exports.default = ChangesetFormFieldsCheckboxGroup;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChangesetFormFieldsCheckboxGroup);
});