define("@algonauti/ember-cable/-private/consumer", ["exports", "@rails/actioncable"], function (_exports, _actioncable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CableConsumer extends _actioncable.Consumer {
    get connectionIsOpen() {
      return this.connection.isOpen();
    }
    reopenConnection() {
      return this.connection.reopen();
    }
    createSubscription(channelName, mixin) {
      return this.subscriptions.create(channelName, mixin);
    }
  }
  _exports.default = CableConsumer;
});