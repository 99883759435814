define("ember-math-helpers/helpers/floor", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.floor = floor;
  /**
   * Executes `Math.floor` on the number passed to the helper.
   *
   * ```hbs
   * {{floor a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.floor`
   * @return {number} The floor of the passed number
   */
  function floor(_ref) {
    let [number] = _ref;
    return Math.floor(number);
  }
  var _default = (0, _helper.helper)(floor);
  _exports.default = _default;
});