define("@ember-data/tracking/-private", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addToTransaction = addToTransaction;
  _exports.addTransactionCB = addTransactionCB;
  _exports.memoTransact = memoTransact;
  _exports.subscribe = subscribe;
  _exports.transact = transact;
  _exports.untracked = untracked;
  /**
   * @module @ember-data/tracking
   */

  let TRANSACTION = null;
  function createTransaction() {
    let transaction = {
      cbs: new Set(),
      props: new Set(),
      sub: new Set(),
      parent: null
    };
    if (TRANSACTION) {
      transaction.parent = TRANSACTION;
    }
    TRANSACTION = transaction;
  }
  function subscribe(obj) {
    if (TRANSACTION) {
      TRANSACTION.sub.add(obj);
    } else {
      obj.ref;
    }
  }
  function flushTransaction() {
    let transaction = TRANSACTION;
    TRANSACTION = transaction.parent;
    transaction.cbs.forEach(cb => {
      cb();
    });
    transaction.props.forEach(obj => {
      // mark this mutation as part of a transaction
      obj.t = true;
      obj.ref = null;
    });
    transaction.sub.forEach(obj => {
      obj.ref;
    });
  }
  async function untrack() {
    let transaction = TRANSACTION;
    TRANSACTION = transaction.parent;

    // defer writes
    await Promise.resolve();
    transaction.cbs.forEach(cb => {
      cb();
    });
    transaction.props.forEach(obj => {
      // mark this mutation as part of a transaction
      obj.t = true;
      obj.ref = null;
    });
  }
  function addToTransaction(obj) {
    if (TRANSACTION) {
      TRANSACTION.props.add(obj);
    } else {
      obj.ref = null;
    }
  }
  function addTransactionCB(method) {
    if (TRANSACTION) {
      TRANSACTION.cbs.add(method);
    } else {
      method();
    }
  }

  /**
   * Run `method` without subscribing to any tracked properties
   * controlled by EmberData.
   *
   * This should rarely be used except by libraries that really
   * know what they are doing. It is most useful for wrapping
   * certain kinds of fetch/query logic from within a `Resource`
   * `hook` or other similar pattern.
   *
   * @function untracked
   * @public
   * @param method
   * @returns result of invoking method
   */
  function untracked(method) {
    createTransaction();
    const ret = method();
    void untrack();
    return ret;
  }

  /**
   *
   * @function transact
   * @public
   * @param method
   * @returns result of invoking method
   */
  function transact(method) {
    createTransaction();
    const ret = method();
    flushTransaction();
    return ret;
  }

  /**
   *
   * @method memoTransact
   * @public
   * @param method
   * @returns a function that will invoke method in a transaction with any provided args and return its result
   */
  function memoTransact(method) {
    return function () {
      createTransaction();
      const ret = method(...arguments);
      flushTransaction();
      return ret;
    };
  }
});