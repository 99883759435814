define("ember-svg-jar/inlined/wave", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><linearGradient x1=\"50%\" y1=\"-10.959%\" x2=\"50%\" y2=\"100%\" id=\"a\"><stop stop-color=\"#00A7FF\" stop-opacity=\".25\" offset=\"0%\"/><stop stop-color=\"#0C7BF9\" offset=\"100%\"/></linearGradient></defs><path d=\"M0 121C311 121 409.898-.25 811-.002 1211-.002 1311 121 1600 121v597H0V121z\" transform=\"matrix(-1 0 0 1 1600 0)\" fill=\"url(#a)\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "1600",
      "height": "718",
      "viewBox": "0 0 1600 718",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});