define("ember-printable-pages/components/printable-pages/table-of-contents", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="PrintablePages-toc" data-test-toc>
    {{yield
      (hash
        layout=(component
          "printable-pages/chapter"
          isToc=true
          chapters=@chapters
          lastReportPage=@lastReportPage
          pageLayout=@pageLayout
          registerChapter=@registerChapter
          registerSection=@registerSection
          addPage=@addPage
          checkIfComplete=@checkIfComplete
          triggerRerender=@triggerRerender
        )
        meta=(hash
          chapters=this.chapterMetaWithoutToc
          chaptersIncludingTableOfContents=this.chapterMeta
        )
      )
    }}
  </div>
  
  */
  {
    "id": "/ePiEjve",
    "block": "[[[10,0],[14,0,\"PrintablePages-toc\"],[14,\"data-test-toc\",\"\"],[12],[1,\"\\n  \"],[18,9,[[28,[37,1],null,[[\"layout\",\"meta\"],[[50,\"printable-pages/chapter\",0,null,[[\"isToc\",\"chapters\",\"lastReportPage\",\"pageLayout\",\"registerChapter\",\"registerSection\",\"addPage\",\"checkIfComplete\",\"triggerRerender\"],[true,[30,1],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]]],[28,[37,1],null,[[\"chapters\",\"chaptersIncludingTableOfContents\"],[[30,0,[\"chapterMetaWithoutToc\"]],[30,0,[\"chapterMeta\"]]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@chapters\",\"@lastReportPage\",\"@pageLayout\",\"@registerChapter\",\"@registerSection\",\"@addPage\",\"@checkIfComplete\",\"@triggerRerender\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-printable-pages/components/printable-pages/table-of-contents.hbs",
    "isStrictMode": false
  });
  class TableOfContents extends _component2.default {
    get chapterMeta() {
      return this.args.chapters.map(c => ({
        startPage: c.startPage,
        endPage: c.endPage,
        name: c.name,
        isToc: c.isToc
      }));
    }
    get chapterMetaWithoutToc() {
      return this.chapterMeta.filter(c => !c.isToc);
    }
  }
  _exports.default = TableOfContents;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TableOfContents);
});