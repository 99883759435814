define("ember-concurrency/-private/scheduler/ember-scheduler", ["exports", "ember-concurrency/-private/external/scheduler/scheduler", "@ember/runloop"], function (_exports, _scheduler, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EmberScheduler extends _scheduler.default {
    scheduleRefresh() {
      (0, _runloop.once)(this, this.refresh);
    }
  }
  var _default = EmberScheduler;
  _exports.default = _default;
});