define("ember-svg-jar/inlined/arrow-up-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M88 166.059V468c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12V166.059h46.059c21.382 0 32.09-25.851 16.971-40.971l-86.059-86.059c-9.373-9.373-24.569-9.373-33.941 0l-86.059 86.059c-15.119 15.119-4.411 40.971 16.971 40.971H88z\"/>",
    "attrs": {
      "aria-hidden": "true",
      "data-prefix": "fas",
      "data-icon": "long-arrow-alt-up",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 256 512",
      "class": "svg-inline--fa fa-long-arrow-alt-up fa-w-8 fa-5x"
    }
  };
  _exports.default = _default;
});