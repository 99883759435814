define("ember-svg-jar/inlined/arrow-alt-up-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M151 377.941V134.059h46.059c21.382 0 32.09-25.851 16.971-40.971L127.971 7.029c-9.373-9.373-24.568-9.373-33.941 0L7.971 93.088c-15.119 15.119-4.411 40.971 16.971 40.971H71v243.882h80z\" fill=\"#000\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "222",
      "height": "378",
      "viewBox": "0 0 222 378",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});