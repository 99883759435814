define("ember-container-query/helpers/cq-width", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function cqWidth(positional, named) {
    var _ref, _ref2;
    const dimension = 'width';
    const max = (_ref = named['max']) !== null && _ref !== void 0 ? _ref : Infinity;
    const min = (_ref2 = named['min']) !== null && _ref2 !== void 0 ? _ref2 : 0;
    return {
      dimension,
      max,
      min
    };
  }
  var _default = (0, _helper.helper)(cqWidth);
  _exports.default = _default;
});