define("@frontile/forms/components/form-textarea", ["exports", "@ember/component", "@ember/template-factory", "@frontile/forms/components/form-input"], function (_exports, _component, _templateFactory, _formInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FormField
    @size={{@size}}
    class={{use-frontile-class "form-textarea" @size class=@containerClass}}
    as |f|
  >
    {{#if @label}}
      <f.Label
        class={{use-frontile-class "form-textarea" @size part="label"}}
      >
        {{@label}}
      </f.Label>
    {{/if}}
  
    {{#if @hint}}
      <f.Hint
        class={{use-frontile-class "form-textarea" @size part="hint"}}
      >
        {{@hint}}
      </f.Hint>
    {{/if}}
  
    <f.Textarea
      {{on "focus" this.handleFocusIn}}
      {{on "blur" this.handleFocusOut}}
  
      @onInput={{@onInput}}
      @onChange={{@onChange}}
      @value={{@value}}
  
      class={{use-frontile-class "form-textarea" @size part="textarea"}}
      aria-invalid={{if this.showErrorFeedback "true"}}
      aria-describedby="{{if @hint f.hintId}}{{if this.showErrorFeedback (concat " " f.feedbackId)}}"
      ...attributes
    />
  
    {{yield}}
  
    {{#if this.showErrorFeedback}}
      <f.Feedback
        class={{use-frontile-class "form-textarea" @size part="feedback"}}
        @errors={{@errors}}
      />
    {{/if}}
  </FormField>
  
  */
  {
    "id": "UmTS9krF",
    "block": "[[[8,[39,0],[[16,0,[28,[37,1],[\"form-textarea\",[30,1]],[[\"class\"],[[30,2]]]]]],[[\"@size\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,4],[[[1,\"    \"],[8,[30,3,[\"Label\"]],[[16,0,[28,[37,1],[\"form-textarea\",[30,1]],[[\"part\"],[\"label\"]]]]],null,[[\"default\"],[[[[1,\"\\n      \"],[1,[30,4]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,5],[[[1,\"    \"],[8,[30,3,[\"Hint\"]],[[16,0,[28,[37,1],[\"form-textarea\",[30,1]],[[\"part\"],[\"hint\"]]]]],null,[[\"default\"],[[[[1,\"\\n      \"],[1,[30,5]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[30,3,[\"Textarea\"]],[[16,0,[28,[37,1],[\"form-textarea\",[30,1]],[[\"part\"],[\"textarea\"]]]],[16,\"aria-invalid\",[52,[30,0,[\"showErrorFeedback\"]],\"true\"]],[16,\"aria-describedby\",[29,[[52,[30,5],[30,3,[\"hintId\"]]],[52,[30,0,[\"showErrorFeedback\"]],[28,[37,3],[\" \",[30,3,[\"feedbackId\"]]],null]]]]],[17,6],[4,[38,4],[\"focus\",[30,0,[\"handleFocusIn\"]]],null],[4,[38,4],[\"blur\",[30,0,[\"handleFocusOut\"]]],null]],[[\"@onInput\",\"@onChange\",\"@value\"],[[30,7],[30,8],[30,9]]],null],[1,\"\\n\\n  \"],[18,11,null],[1,\"\\n\\n\"],[41,[30,0,[\"showErrorFeedback\"]],[[[1,\"    \"],[8,[30,3,[\"Feedback\"]],[[16,0,[28,[37,1],[\"form-textarea\",[30,1]],[[\"part\"],[\"feedback\"]]]]],[[\"@errors\"],[[30,10]]],null],[1,\"\\n\"]],[]],null]],[3]]]]],[1,\"\\n\"]],[\"@size\",\"@containerClass\",\"f\",\"@label\",\"@hint\",\"&attrs\",\"@onInput\",\"@onChange\",\"@value\",\"@errors\",\"&default\"],false,[\"form-field\",\"use-frontile-class\",\"if\",\"concat\",\"on\",\"yield\"]]",
    "moduleName": "@frontile/forms/components/form-textarea.hbs",
    "isStrictMode": false
  });
  class FormTextarea extends _formInput.FormInputBase {}
  _exports.default = FormTextarea;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormTextarea);
});