define("ember-composable-helpers/helpers/repeat", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.repeat = repeat;
  function repeat(_ref) {
    let [length, value] = _ref;
    if ((0, _utils.typeOf)(length) !== 'number') {
      return [value];
    }
    return Array.apply(null, {
      length
    }).map(() => value); // eslint-disable-line
  }
  var _default = (0, _helper.helper)(repeat);
  _exports.default = _default;
});