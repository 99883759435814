define("ember-data-storefront/-private/record-array-query", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RecordArrayQuery {
    constructor(store, type) {
      let params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      this.store = store;
      this.type = type;
      this.params = params;
      this.value = null;
    }
    run() {
      let promise;
      if (this.value) {
        promise = this.value.update();
      } else {
        promise = this.store.query(this.type, this.params).then(records => {
          this.value = records;
          return records;
        });
      }
      return promise;
    }
    trackIncludes() {
      let includes = this.params && this.params.include;
      let models = this.value;
      if (includes && models) {
        models.filter(model => model.trackLoadedIncludes).forEach(model => {
          model.trackLoadedIncludes(includes);
        });
      }
    }
  }
  _exports.default = RecordArrayQuery;
});