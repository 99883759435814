define("ember-printable-pages/utils/logger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.group = group;
  _exports.groupEnd = groupEnd;
  _exports.log = log;
  const ENABLE_LOGS = false;
  function log() {
    if (ENABLE_LOGS) console.log(...arguments);
  }
  function group() {
    if (ENABLE_LOGS) console.group(...arguments);
  }
  function groupEnd() {
    if (ENABLE_LOGS) console.groupEnd(...arguments);
  }
});