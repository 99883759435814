define("ember-math-helpers/helpers/clz32", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.clz32 = clz32;
  _exports.default = void 0;
  /**
   * Executes `Math.clz32` on the number passed to the helper.
   *
   * ```hbs
   * {{clz32 a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.clz32`
   * @return {number} The clz32 of the passed number
   */
  function clz32(_ref) {
    let [number] = _ref;
    return Math.clz32(number);
  }
  var _default = (0, _helper.helper)(clz32);
  _exports.default = _default;
});