define("ember-svg-jar/inlined/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"current-fill\" d=\"M21.654 18l3.98-3.98a1.251 1.251 0 000-1.77l-.885-.884a1.251 1.251 0 00-1.769 0L19 15.346l-3.98-3.98a1.251 1.251 0 00-1.77 0l-.884.885a1.251 1.251 0 000 1.769l3.98 3.98-3.98 3.98a1.251 1.251 0 000 1.77l.885.884c.488.488 1.28.488 1.769 0l3.98-3.98 3.98 3.98c.488.488 1.28.488 1.77 0l.884-.885a1.251 1.251 0 000-1.769L21.654 18z\" fill=\"#4C637D\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "class": "current-fill",
      "width": "38",
      "height": "36",
      "viewBox": "0 0 38 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});