define("ember-printable-pages/components/printable-pages/page-layout", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="PrintablePages-pageLayout">
    <div
      class="PrintablePages-pageContainer"
      data-test-page={{@pageNumber}}
      {{style
        paddingTop=@pageLayout.top
        paddingRight=@pageLayout.right
        paddingBottom=@pageLayout.bottom
        paddingLeft=@pageLayout.left
      }}
    >
      <section
        class="PrintablePages-page"
        {{style height=@pageLayout.innerHeight width=@pageLayout.innerWidth}}
      >
        {{yield}}
      </section>
    </div>
  
  </div>
  */
  {
    "id": "Oksww02N",
    "block": "[[[10,0],[14,0,\"PrintablePages-pageLayout\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"PrintablePages-pageContainer\"],[16,\"data-test-page\",[30,1]],[4,[38,0],null,[[\"paddingTop\",\"paddingRight\",\"paddingBottom\",\"paddingLeft\"],[[30,2,[\"top\"]],[30,2,[\"right\"]],[30,2,[\"bottom\"]],[30,2,[\"left\"]]]]],[12],[1,\"\\n    \"],[11,\"section\"],[24,0,\"PrintablePages-page\"],[4,[38,0],null,[[\"height\",\"width\"],[[30,2,[\"innerHeight\"]],[30,2,[\"innerWidth\"]]]]],[12],[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[13]],[\"@pageNumber\",\"@pageLayout\",\"&default\"],false,[\"style\",\"yield\"]]",
    "moduleName": "ember-printable-pages/components/printable-pages/page-layout.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});