define("ember-svg-jar/inlined/loop-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M251.9 61.3c0 34.4-24.2 61.3-55.1 61.3-34.8 0-63.9-22.8-75.9-59.4-10.3-31.5-35-51.1-64.5-51.1H56c-24.2 0-43.1 21.6-43.1 49.3 0 27.6 18.9 49.3 43.1 49.3h.4c20.6 0 38.1-9.3 52-27.5 2-2.6 5.8-3.2 8.4-1.1 2.6 2 3.2 5.8 1.1 8.4-16 21.1-37.3 32.3-61.5 32.3H56C25.1 122.8.9 95.9.9 61.5S25.1.2 56 .2h.4c34.8 0 63.9 22.8 75.9 59.4 10.3 31.5 35 51.1 64.5 51.1 24.2 0 43.1-21.6 43.1-49.3 0-27.6-18.9-49.3-43.1-49.3-20.6 0-38.1 9.3-52 27.5-1.2 1.6-3 2.4-4.8 2.4-1.3 0-2.5-.4-3.6-1.2-2.6-2-3.2-5.8-1.1-8.4C151.3 11.3 172.6.1 196.8.1c30.9 0 55.1 26.9 55.1 61.2z\" fill=\"#000\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "252",
      "height": "123",
      "viewBox": "0 0 252 123",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});