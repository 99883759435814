define("ember-changeset-conditional-validations/validators/sometimes", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateSometimes;
  function validateSometimes(validator, condition) {
    if (Array.isArray(arguments[0])) {
      let validators = arguments[0];
      return validators.map(guardValidatorWithCondition);
    } else {
      let validator = arguments[0];
      return guardValidatorWithCondition(validator);
    }
    function guardValidatorWithCondition(validator) {
      return function (key, newValue, oldValue, changes, content) {
        let thisValue = {
          get(property) {
            if (property.includes('.')) {
              let changesValue = (0, _object.get)(changes, property);
              if (typeof changesValue !== 'undefined') {
                return changesValue;
              }

              // Check if the `changes` value is explicitly undefined,
              // or if it's not present at all.
              let pathSegments = property.split('.');
              let propName = pathSegments.pop();
              let objPath = pathSegments.join('.');
              let obj = (0, _object.get)(changes, objPath);
              if (obj && obj.hasOwnProperty && obj.hasOwnProperty(propName)) {
                return changesValue;
              }
              return (0, _object.get)(content, property);
            }
            if (changes.hasOwnProperty(property)) {
              return (0, _object.get)(changes, property);
            } else {
              return (0, _object.get)(content, property);
            }
          }
        };
        if (condition.call(thisValue, changes, content)) {
          return validator(key, newValue, oldValue, changes, content);
        }
        return true;
      };
    }
  }
});