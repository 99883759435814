define("@frontile/changeset-form/components/changeset-form/fields/checkbox", ["exports", "@ember/component", "@ember/template-factory", "@frontile/changeset-form/components/changeset-form/fields/base", "@ember/object"], function (_exports, _component, _templateFactory, _base, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FormCheckbox
    @onChange={{this.handleChange}}
    @checked={{this.value}}
    @hint={{@hint}}
    @name={{@name}}
    @containerClass={{@containerClass}}
    @size={{@size}}
    ...attributes
  >
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      {{@label}}
    {{/if}}
  </FormCheckbox>
  */
  {
    "id": "B/OD59zG",
    "block": "[[[8,[39,0],[[17,1]],[[\"@onChange\",\"@checked\",\"@hint\",\"@name\",\"@containerClass\",\"@size\"],[[30,0,[\"handleChange\"]],[30,0,[\"value\"]],[30,2],[30,3],[30,4],[30,5]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"    \"],[18,7,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,6]],[1,\"\\n\"]],[]]]],[]]]]]],[\"&attrs\",\"@hint\",\"@name\",\"@containerClass\",\"@size\",\"@label\",\"&default\"],false,[\"form-checkbox\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@frontile/changeset-form/components/changeset-form/fields/checkbox.hbs",
    "isStrictMode": false
  });
  let ChangesetFormFieldsCheckbox = (_class = class ChangesetFormFieldsCheckbox extends _base.default {
    get fullFieldName() {
      return this.args._groupName ? "".concat(this.args._groupName, ".").concat(this.args.fieldName) : this.args.fieldName;
    }
    get value() {
      return this.args.changeset.get(this.fullFieldName);
    }
    get errors() {
      if (typeof this.args.errors !== 'undefined') {
        return this.args.errors;
      }
      const fieldErrors = this.args.changeset.errors.filter(error => {
        return error.key === this.fullFieldName;
      });
      return fieldErrors.reduce((errors, error) => {
        return [...errors, ...error.validation];
      }, []);
    }
    async validate() {
      await this.args.changeset.validate(this.fullFieldName);
    }
    async handleChange(value, event) {
      this.args.changeset.set(this.fullFieldName, value);
      await this.validate();
      if (typeof this.args._parentOnChange === 'function') {
        this.args._parentOnChange(value, event);
      }
      if (typeof this.args.onChange === 'function') {
        this.args.onChange(value, event);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "validate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype)), _class);
  _exports.default = ChangesetFormFieldsCheckbox;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChangesetFormFieldsCheckbox);
});