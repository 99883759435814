define("ember-svg-jar/inlined/hifi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M269 14.5V9.545h-1V14.5h-3.5a1 1 0 00-1 1v4a1 1 0 001 1h3.5v21.109h1V20.5h3.5a1 1 0 001-1v-4a1 1 0 00-1-1H269zm-12 6V9.545h-1V20.5h-3.5a1 1 0 00-1 1v4a1 1 0 001 1h3.5v15.109h1V26.5h3.5a1 1 0 001-1v-4a1 1 0 00-1-1H257zm-13 6V9.545h-1V26.5h-3.5a1 1 0 00-1 1v4a1 1 0 001 1h3.5v9.109h1V32.5h3.5a1 1 0 001-1v-4a1 1 0 00-1-1H244zm-12-6V9.545h-1V20.5h-3.5a1 1 0 00-1 1v4a1 1 0 001 1h3.5v15.109h1V26.5h3.5a1 1 0 001-1v-4a1 1 0 00-1-1H232zm-12-6V9.545h-1V14.5h-3.5a1 1 0 00-1 1v4a1 1 0 001 1h3.5v21.109h1V20.5h3.5a1 1 0 001-1v-4a1 1 0 00-1-1H220zM12.5 77h-8A3.5 3.5 0 011 73.5v-69A3.5 3.5 0 014.5 1h274a3.5 3.5 0 013.5 3.5v69a3.5 3.5 0 01-3.5 3.5h-7v2.5h-35V77h-189v2.5h-35V77zm-8-75A2.5 2.5 0 002 4.5v69A2.5 2.5 0 004.5 76h274a2.5 2.5 0 002.5-2.5v-69a2.5 2.5 0 00-2.5-2.5H4.5zm3 3.5h268a2 2 0 012 2v37a2 2 0 01-2 2H7.5a2 2 0 01-2-2v-37a2 2 0 012-2zm36 61a7 7 0 110-14 7 7 0 010 14zm64 0a7 7 0 110-14 7 7 0 010 14zm86 0a7 7 0 110-14 7 7 0 010 14zm20 0a7 7 0 110-14 7 7 0 010 14zm55-2a5 5 0 110-10 5 5 0 010 10zm-203-11a4 4 0 014 4v4a4 4 0 11-8 0v-4a4 4 0 014-4zm-49 0a4 4 0 014 4v4a4 4 0 11-8 0v-4a4 4 0 014-4zm60 0a4 4 0 014 4v4a4 4 0 11-8 0v-4a4 4 0 014-4zm86 0a4 4 0 014 4v4a4 4 0 11-8 0v-4a4 4 0 014-4zm-75 0a4 4 0 014 4v4a4 4 0 11-8 0v-4a4 4 0 014-4zm86 0a4 4 0 014 4v4a4 4 0 11-8 0v-4a4 4 0 014-4zm-145 9a3 3 0 110-6 3 3 0 010 6zm229 0a3 3 0 110-6 3 3 0 010 6zm-192-3a2 2 0 100-4 2 2 0 000 4zm-49 0a2 2 0 100-4 2 2 0 000 4zm60 0a2 2 0 100-4 2 2 0 000 4zm86 0a2 2 0 100-4 2 2 0 000 4zm-75 0a2 2 0 100-4 2 2 0 000 4zm86 0a2 2 0 100-4 2 2 0 000 4zm20-24c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z\" fill=\"#EBEFEE\" fill-rule=\"nonzero\" stroke=\"#4E4E4E\"/>",
    "attrs": {
      "width": "283",
      "height": "80",
      "viewBox": "0 0 283 80",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});