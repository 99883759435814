define("ember-svg-jar/inlined/x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.654 7l3.98-3.98a1.251 1.251 0 000-1.77l-.885-.884a1.251 1.251 0 00-1.769 0L7 4.346 3.02.366a1.251 1.251 0 00-1.77 0l-.884.885a1.251 1.251 0 000 1.769L4.346 7l-3.98 3.98a1.251 1.251 0 000 1.77l.885.884c.488.488 1.28.488 1.769 0L7 9.654l3.98 3.98c.488.488 1.28.488 1.77 0l.884-.885a1.251 1.251 0 000-1.769L9.654 7z\" fill=\"#000\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});