define("@frontile/core/components/visually-hidden", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="sr-only" ...attributes>
    {{yield}}
  </div>
  */
  {
    "id": "1hob1oWe",
    "block": "[[[11,0],[24,0,\"sr-only\"],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "@frontile/core/components/visually-hidden.hbs",
    "isStrictMode": false
  });
  class VisuallyHidden extends _component2.default {}
  _exports.default = VisuallyHidden;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VisuallyHidden);
});