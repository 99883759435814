define("ember-modal-dialog/components/basic-dialog", ["exports", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/component", "@ember/utils", "ember-modal-dialog/templates/components/basic-dialog", "@ember/string"], function (_exports, _component, _object, _service, _component2, _utils, _basicDialog, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let BasicDialog = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_basicDialog.default), _dec3 = (0, _service.inject)('modal-dialog'), _dec4 = (0, _object.computed)('attachmentClass', 'containerClass', 'containerClassNames.{[],join}', 'targetAttachmentClass'), _dec5 = (0, _object.computed)('overlayClass', 'overlayClassNames.{[],join}', 'translucentOverlay'), _dec6 = (0, _object.computed)('targetAttachmentClass', 'variantWrapperClass', 'wrapperClass', 'wrapperClassNames.{[],join}'), _dec7 = (0, _object.computed)('overlayPosition'), _dec8 = (0, _object.computed)('targetAttachment'), _dec(_class = _dec2(_class = (_class2 = class BasicDialog extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "containerClassNames", null);
      _defineProperty(this, "overlayClassNames", null);
      _defineProperty(this, "wrapperClassNames", null);
      _defineProperty(this, "destinationElementId", null);
      _defineProperty(this, "translucentOverlay", false);
      _defineProperty(this, "clickOutsideToClose", false);
      _defineProperty(this, "hasOverlay", true);
      _defineProperty(this, "isCentered", true);
      _defineProperty(this, "overlayPosition", null);
      _initializerDefineProperty(this, "modalService", _descriptor, this);
      _defineProperty(this, "variantWrapperClass", 'emd-static');
    }
    init() {
      super.init(...arguments);
      if (!this.destinationElementId) {
        (0, _object.set)(this, 'destinationElementId', this.modalService.destinationElementId);
      }
    }
    get containerClassNamesString() {
      var _this$containerClassN, _this$containerClassN2;
      let classNames = ((_this$containerClassN = this.containerClassNames) === null || _this$containerClassN === void 0 ? void 0 : _this$containerClassN.join) && ((_this$containerClassN2 = this.containerClassNames) === null || _this$containerClassN2 === void 0 ? void 0 : _this$containerClassN2.join(' ')) || this.containerClassNames;
      return ['ember-modal-dialog', classNames, this.targetAttachmentClass, this.attachmentClass, this.containerClass].filter(className => !(0, _utils.isEmpty)(className)).join(' ');
    }
    get overlayClassNamesString() {
      var _this$overlayClassNam, _this$overlayClassNam2;
      let classNames = ((_this$overlayClassNam = this.overlayClassNames) === null || _this$overlayClassNam === void 0 ? void 0 : _this$overlayClassNam.join) && ((_this$overlayClassNam2 = this.overlayClassNames) === null || _this$overlayClassNam2 === void 0 ? void 0 : _this$overlayClassNam2.join(' ')) || this.overlayClassNames;
      return ['ember-modal-overlay', classNames, this.translucentOverlay ? 'translucent' : null, this.overlayClass].filter(className => !(0, _utils.isEmpty)(className)).join(' ');
    }
    get wrapperClassNamesString() {
      var _this$wrapperClassNam, _this$wrapperClassNam2;
      let classNames = ((_this$wrapperClassNam = this.wrapperClassNames) === null || _this$wrapperClassNam === void 0 ? void 0 : _this$wrapperClassNam.join) && ((_this$wrapperClassNam2 = this.wrapperClassNames) === null || _this$wrapperClassNam2 === void 0 ? void 0 : _this$wrapperClassNam2.join(' ')) || this.wrapperClassNames;
      return ['ember-modal-wrapper', classNames, this.targetAttachmentClass.replace('emd-', 'emd-wrapper-'), this.variantWrapperClass, this.wrapperClass].filter(className => !(0, _utils.isEmpty)(className)).join(' ');
    }
    get isOverlaySibling() {
      return this.overlayPosition === 'sibling';
    }
    get targetAttachmentClass() {
      let targetAttachment = this.targetAttachment || '';
      // Convert tether-styled values like 'middle right' to 'right'
      targetAttachment = targetAttachment.split(' ').slice(-1)[0];
      return "ember-modal-dialog-target-attachment-".concat((0, _string.dasherize)(targetAttachment), " emd-target-attachment-").concat((0, _string.dasherize)(targetAttachment));
    }
    didInsertElement() {
      if (!this.clickOutsideToClose) {
        return;
      }
      this.makeOverlayClickableOnIOS();
      this.handleClick = _ref => {
        let {
          target
        } = _ref;
        // if the click has already resulted in the target
        // being removed or hidden, do nothing
        if (target.offsetWidth === 0 && target.offsetHeight === 0) {
          return;
        }
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        let modalSelector = '.ember-modal-dialog';
        if (this.stack) {
          modalSelector = '#' + this.stack + modalSelector;
        }

        // if the click is within the dialog, do nothing
        let modalEl = document.querySelector(modalSelector);
        if (modalEl && modalEl.contains(target)) {
          return;
        }
        if (this.onClose) {
          this.onClose();
        }
      };
      const registerClick = () => document.addEventListener('click', this.handleClick);

      // setTimeout needed or else the click handler will catch the click that spawned this modal dialog
      setTimeout(registerClick);
      if (this.isIOS) {
        const registerTouch = () => document.addEventListener('touchend', this.handleClick);
        setTimeout(registerTouch);
      }
      super.didInsertElement(...arguments);
    }
    willDestroyElement() {
      document.removeEventListener('click', this.handleClick);
      if (this.isIOS) {
        document.removeEventListener('touchend', this.handleClick);
      }
      super.willDestroyElement(...arguments);
    }
    get isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent);
    }
    makeOverlayClickableOnIOS() {
      if (this.isIOS) {
        let overlayEl = document.querySelector('div[data-emd-overlay]');
        if (overlayEl) {
          overlayEl.style.cursor = 'pointer';
        }
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "modalService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "containerClassNamesString", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "containerClassNamesString"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "overlayClassNamesString", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "overlayClassNamesString"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "wrapperClassNamesString", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "wrapperClassNamesString"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isOverlaySibling", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "isOverlaySibling"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "targetAttachmentClass", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "targetAttachmentClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isIOS", [_object.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "isIOS"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = BasicDialog;
});