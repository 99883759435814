define("ember-data-storefront/instance-initializers/mixin-storefront", ["exports", "ember-data-storefront/mixins/loadable-store"], function (_exports, _loadableStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(appInstance) {
    let store = appInstance.lookup('service:store');
    store.reopen(_loadableStore.default);
    store.resetCache();
  }
  var _default = {
    name: 'mixin-storefront',
    after: 'ember-data',
    initialize
  };
  _exports.default = _default;
});