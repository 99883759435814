define("ember-svg-jar/inlined/arrow-alt-square-up-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M0 432V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48zm268-60V256h70.9c10.7 0 16.1-13 8.5-20.5L232.5 121.2c-4.7-4.7-12.2-4.7-16.9 0l-115 114.3C93 243 98.4 256 109.1 256H180v116c0 6.6 5.4 12 12 12h64c6.6 0 12-5.4 12-12z\"/>",
    "attrs": {
      "aria-hidden": "true",
      "data-prefix": "fas",
      "data-icon": "arrow-alt-square-up",
      "class": "svg-inline--fa fa-arrow-alt-square-up fa-w-14",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 448 512"
    }
  };
  _exports.default = _default;
});