define("ember-math-helpers/helpers/hypot", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hypot = hypot;
  /**
   * Takes two or more numbers, and returns the square root of the sum of squares of them using `Math.hypot`
   *
   * ```hbs
   * {{max a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to pass to `Math.hypot`
   * @return {number} The hypot of the set of numbers
   */
  function hypot(numbers) {
    return Math.hypot(...numbers);
  }
  var _default = (0, _helper.helper)(hypot);
  _exports.default = _default;
});