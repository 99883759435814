define("ember-svg-jar/inlined/chevron-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.148 6.312l-5.797 5.789q-.148.148-.352.148t-.352-.148L.85 6.312q-.148-.148-.148-.355t.148-.355l1.297-1.289q.148-.148.352-.148t.352.148l4.148 4.148 4.148-4.148q.148-.148.352-.148t.352.148l1.297 1.289q.148.148.148.355t-.148.355z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});