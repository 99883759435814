define("ember-printable-pages/util-models/section", ["exports", "@glimmer/tracking", "ember-printable-pages/util-models/page", "@ember/object", "ember-printable-pages/utils/logger"], function (_exports, _tracking, _page, _object, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let Section = (_class = class Section {
    constructor() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      _initializerDefineProperty(this, "pages", _descriptor, this);
      _defineProperty(this, "id", void 0);
      _defineProperty(this, "columnCount", 1);
      _defineProperty(this, "nextItemIndex", 0);
      _defineProperty(this, "renderDataLength", 0);
      _defineProperty(this, "maxItemHeight", null);
      _defineProperty(this, "minItemHeight", null);
      _defineProperty(this, "isFullyRendered", false);
      _defineProperty(this, "data", []);
      let {
        id,
        index,
        columnCount,
        data
      } = options;
      this.id = id;
      this.index = index;
      this.columnCount = columnCount || 1;
      this.data = data;
    }
    get itemHeightDiff() {
      return this.maxItemHeight - this.minItemHeight;
    }
    itemCountForPage(pageIndex) {
      let page = this.pages.at(pageIndex);
      if (!page) return 0;
      return page.endIndex - page.startIndex + 1;
    }
    reconcilePageStartIndex(pageIndex) {
      let previousPage = this.pages.at(pageIndex - 1);
      let startIndex = previousPage.endIndex + 1;
      let page = this.pages.at(pageIndex);
      if (!page) {
        (0, _logger.log)("Section:".concat(this.id, " #reconcilePageStartIndex --- addPage"));
        page = this.addPage(pageIndex, startIndex);
      } else {
        page.startIndex = startIndex;
      }
      this.nextItemIndex = this.nextItemIndex + 1;
      (0, _logger.log)("Section:".concat(this.id, " #reconcilePageStartIndex"), "".concat(pageIndex, " : ").concat(page.startIndex));

      // this.updateIsFullyRendered();
    }

    // seems like this could be a getter
    updateIsFullyRendered() {
      (0, _logger.log)("Section:".concat(this.id, " #updateIsFullyRendered"), this.nextItemIndex >= this.data.length);
      this.isFullyRendered = this.nextItemIndex >= this.data.length;
    }
    addPage(pageIndex, startIndex) {
      (0, _logger.log)("Section:".concat(this.id, " #addPage"));
      let page = new _page.default({
        startIndex: startIndex,
        endIndex: startIndex
      });
      if (this.pages.length === 0) {
        this.pages = [...Array(pageIndex), page];
      } else {
        // When a chapter section, post-render, expands in height the content may
        // need to jump several pages forward. In that case fill the empty space
        // in the pages list for this section.
        this.pages = [...this.pages, ...Array(pageIndex - this.pages.length), page];
      }
      return page;
      // this.updateIsFullyRendered();
    }

    addItemToPage(pageIndex) {
      (0, _logger.log)("Section:".concat(this.id, " #addItemToPage"));
      let page = this.pages.at(pageIndex);
      if (!page) {
        this.addPage(pageIndex, 0);
      } else {
        page.endIndex = page.endIndex + 1;
        this.nextItemIndex = this.nextItemIndex + 1;
        // this.updateIsFullyRendered();
      }
    }

    toString() {
      return "<Models::Section:".concat(this.id, "> ").concat(this.data.length, " items, nextItemIndex ").concat(this.nextItemIndex, ", isFullyRendered ").concat(this.isFullyRendered);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "pages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "addPage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addItemToPage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addItemToPage"), _class.prototype)), _class);
  _exports.default = Section;
});