define("ember-svg-jar/inlined/caret-down-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M287.968 160H32.038c-28.425 0-42.767 34.488-22.627 54.627l127.962 128c12.496 12.496 32.758 12.497 45.255 0l127.968-128C330.695 194.528 316.45 160 287.968 160zM160 320L32 192h256L160 320z\"/>",
    "attrs": {
      "aria-hidden": "true",
      "data-prefix": "fal",
      "data-icon": "caret-down",
      "class": "svg-inline--fa fa-caret-down fa-w-10",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 320 512"
    }
  };
  _exports.default = _default;
});