define("ember-svg-jar/inlined/offline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M339.8.4c91.527 91.527 91.527 241.68 0 333.2l-29.117-29.121c75.52-75.508 75.52-199.44 0-274.95L339.8.408V.4zM219 229.31l16.008-182.52c4.215-47.965-66.223-47.965-62.008 0l16.008 182.52h29.996H219zm75.164-173.19c60.91 60.918 60.91 160.85 0 221.76l-29.121-29.117c44.91-44.91 44.91-118.62 0-163.53l29.121-29.117v.004zM97.314 29.522c-75.52 75.516-75.52 199.44 0 274.95l-29.121 29.121c-91.52-91.527-91.52-241.67 0-333.2l29.121 29.121v.008zm45.645 55.711c-44.91 44.918-44.91 118.62 0 163.53l-29.121 29.117c-60.91-60.91-60.91-160.84 0-221.76l29.121 29.117v-.004zm61.035 163.86c20.465 0 37.055 16.59 37.055 37.055 0 20.457-16.59 37.047-37.055 37.047-20.457 0-37.039-16.59-37.039-37.047 0-20.465 16.582-37.055 37.039-37.055z\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "fill": "currentColor",
      "width": "409",
      "height": "339",
      "viewBox": "0 0 409 339",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});