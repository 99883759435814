define("ember-svg-jar/inlined/like-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M107.5 193.4c-3.8 0-7.6-1.3-10.7-4C67.8 164.6.5 102.2.5 57 .5 26.3 26.4.3 57.1.3c22.1 0 40.6 17.1 49.6 27.3.3.3.6.4.8.4.2 0 .5-.1.8-.4 9-10.2 27.4-27.4 49.5-27.4 30.7 0 56.6 26 56.6 56.7 0 20.1-13.3 45.8-39.6 76.5-9.9 11.5-21.5 23.6-34.4 35.9-9.1 8.7-17.1 15.6-22.1 20-3.1 2.7-7 4.1-10.8 4.1zm-50.4-181c-24.2 0-44.6 20.5-44.6 44.7 0 32.8 47.6 85.2 92.1 123.3 1.7 1.5 4.2 1.4 5.9 0 4.9-4.2 12.7-11.1 21.7-19.6 12.6-12 23.9-23.7 33.5-35 24-28 36.7-51.8 36.7-68.7 0-24.2-20.4-44.7-44.6-44.7-17.4 0-32.8 14.6-40.5 23.3-2.5 2.8-6 4.4-9.8 4.4-3.7 0-7.3-1.6-9.8-4.4C90 27 74.5 12.4 57.1 12.4z\" fill=\"#000\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "215",
      "height": "194",
      "viewBox": "0 0 215 194",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});