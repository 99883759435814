define("ember-svg-jar/inlined/squiggle-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1 299l3-7.41-3-7.377v-.172l3-7.41-3-7.377v-.171l3-7.411-3-7.376v-.172l3-7.41-3-7.377v-.171l3-7.411-3-7.376v-.172l3-7.41-3-7.377v-.171l3-7.411-3-7.377v-.171l3-7.41-3-7.377v-.172l3-7.41-3-7.377v-.171l3-7.411-3-7.376v-.172l3-7.41-3-7.377v-.171l3-7.411-3-7.376v-.172l3-7.41-3-7.377v-.171l3-7.411-3-7.377v-.171l3-7.41-3-7.377v-.172l3-7.41-3-7.377v-.171l3-7.411-3-7.376v-.172l3-7.41-3-7.377v-.171l3-7.411-3-7.376v-.172l3-7.41L1 0\" stroke=\"#D2D2D2\" fill=\"none\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "5",
      "height": "299",
      "viewBox": "0 0 5 299",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});