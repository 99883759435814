define("ember-api-actions/decorators", ["exports", "ember-api-actions/utils/collection-action", "ember-api-actions/utils/member-action"], function (_exports, _collectionAction, _memberAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.collectionAction = collectionAction;
  _exports.memberAction = memberAction;
  function collectionAction(options) {
    return function createCollectionActionDescriptor(target, propertyName) {
      return {
        value: (0, _collectionAction.default)(options)
      };
    };
  }
  function memberAction(options) {
    return function createMemberActionDescriptor(target, propertyName) {
      return {
        value: (0, _memberAction.default)(options)
      };
    };
  }
});