define("ember-concurrency/-private/external/task-instance/completion-states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.COMPLETION_SUCCESS = _exports.COMPLETION_PENDING = _exports.COMPLETION_ERROR = _exports.COMPLETION_CANCEL = void 0;
  const COMPLETION_PENDING = 0;
  _exports.COMPLETION_PENDING = COMPLETION_PENDING;
  const COMPLETION_SUCCESS = 1;
  _exports.COMPLETION_SUCCESS = COMPLETION_SUCCESS;
  const COMPLETION_ERROR = 2;
  _exports.COMPLETION_ERROR = COMPLETION_ERROR;
  const COMPLETION_CANCEL = 3;
  _exports.COMPLETION_CANCEL = COMPLETION_CANCEL;
});