define("@frontile/forms/components/form-field/checkbox", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <input
    {{on "change" this.handleChange}}
    id={{@id}}
    name={{@name}}
    checked={{this.isChecked}}
    type="checkbox"
    class={{use-frontile-class "form-field-checkbox" @size}}
    data-test-id="form-field-checkbox"
    ...attributes
  >
  
  */
  {
    "id": "4Ku3geQn",
    "block": "[[[11,\"input\"],[16,1,[30,1]],[16,3,[30,2]],[16,\"checked\",[30,0,[\"isChecked\"]]],[24,4,\"checkbox\"],[16,0,[28,[37,0],[\"form-field-checkbox\",[30,3]],null]],[17,4],[4,[38,1],[\"change\",[30,0,[\"handleChange\"]]],null],[12],[13],[1,\"\\n\"]],[\"@id\",\"@name\",\"@size\",\"&attrs\"],false,[\"use-frontile-class\",\"on\"]]",
    "moduleName": "@frontile/forms/components/form-field/checkbox.hbs",
    "isStrictMode": false
  });
  let FormFieldCheckbox = (_class = class FormFieldCheckbox extends _component2.default {
    get isChecked() {
      return !!this.args.checked;
    }
    handleChange(event) {
      event.preventDefault();
      const value = !this.args.checked;
      if (typeof this.args.onChange === 'function') {
        this.args.onChange(value, event);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype)), _class);
  _exports.default = FormFieldCheckbox;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormFieldCheckbox);
});