define("@frontile/forms/components/form-field/feedback", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    id={{@id}}
    class={{use-frontile-class "form-field-feedback" @size (if this.isError "error")}}
    data-test-id="form-field-feedback"
    aria-live={{if this.isError "assertive" "polite"}}
    ...attributes
  >
    {{this.errorMessage}}
    {{yield}}
  </div>
  
  */
  {
    "id": "ozWqHSZi",
    "block": "[[[11,0],[16,1,[30,1]],[16,0,[28,[37,0],[\"form-field-feedback\",[30,2],[52,[30,0,[\"isError\"]],\"error\"]],null]],[16,\"aria-live\",[52,[30,0,[\"isError\"]],\"assertive\",\"polite\"]],[17,3],[12],[1,\"\\n  \"],[1,[30,0,[\"errorMessage\"]]],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@id\",\"@size\",\"&attrs\",\"&default\"],false,[\"use-frontile-class\",\"if\",\"yield\"]]",
    "moduleName": "@frontile/forms/components/form-field/feedback.hbs",
    "isStrictMode": false
  });
  class FormFieldFeedback extends _component2.default {
    get isError() {
      return typeof this.args.errors !== 'undefined' || this.args.isError === true;
    }
    get errorMessage() {
      if (!this.args.errors) return '';
      if (typeof this.args.errors === 'string') {
        return this.args.errors;
      } else {
        return this.args.errors.join('; ');
      }
    }
  }
  _exports.default = FormFieldFeedback;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormFieldFeedback);
});