define("ember-table/components/ember-td/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isFirstColumn}}
    <div class="et-cell-container">
      {{#if this.canSelect}}
        <span
          class="et-toggle-select {{unless this.shouldShowCheckbox 'et-speech-only'}}"
          data-test-select-row-container
        >
          <EmberTableSimpleCheckbox
            @checked={{this.rowMeta.isGroupSelected}}
            @onClick={{action "onSelectionToggled"}}
            @ariaLabel="Select row"
            @dataTestSelectRow={{this.isTesting}}
          />
          <span></span>
        </span>
      {{/if}}
  
      {{#if this.canCollapse}}
        <span class="et-toggle-collapse et-depth-indent {{this.depthClass}}">
          <EmberTableSimpleCheckbox
            @checked={{this.rowMeta.isCollapsed}}
            @onChange={{action "onCollapseToggled"}}
            @ariaLabel="Collapse row"
            @dataTestCollapseRow={{this.isTesting}}
          />
          <span></span>
        </span>
      {{else}}
        <div class="et-depth-indent et-depth-placeholder {{this.depthClass}}"></div>
      {{/if}}
  
      <div class="et-cell-content">
        {{#if (has-block)}}
          {{yield this.cellValue this.columnValue this.rowValue this.cellMeta this.columnMeta this.rowMeta this.rowsCount}}
        {{else}}
          {{this.cellValue}}
        {{/if}}
      </div>
    </div>
  {{else}}
    {{#if (has-block)}}
      {{yield this.cellValue this.columnValue this.rowValue this.cellMeta this.columnMeta this.rowMeta this.rowsCount}}
    {{else}}
      {{this.cellValue}}
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "OevSSQhc",
    "block": "[[[41,[30,0,[\"isFirstColumn\"]],[[[1,\"  \"],[10,0],[14,0,\"et-cell-container\"],[12],[1,\"\\n\"],[41,[30,0,[\"canSelect\"]],[[[1,\"      \"],[10,1],[15,0,[29,[\"et-toggle-select \",[52,[51,[30,0,[\"shouldShowCheckbox\"]]],\"et-speech-only\"]]]],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@checked\",\"@onClick\",\"@ariaLabel\",\"@dataTestSelectRow\"],[[30,0,[\"rowMeta\",\"isGroupSelected\"]],[28,[37,3],[[30,0],\"onSelectionToggled\"],null],\"Select row\",[30,0,[\"isTesting\"]]]],null],[1,\"\\n        \"],[10,1],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"canCollapse\"]],[[[1,\"      \"],[10,1],[15,0,[29,[\"et-toggle-collapse et-depth-indent \",[30,0,[\"depthClass\"]]]]],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@checked\",\"@onChange\",\"@ariaLabel\",\"@dataTestCollapseRow\"],[[30,0,[\"rowMeta\",\"isCollapsed\"]],[28,[37,3],[[30,0],\"onCollapseToggled\"],null],\"Collapse row\",[30,0,[\"isTesting\"]]]],null],[1,\"\\n        \"],[10,1],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[15,0,[29,[\"et-depth-indent et-depth-placeholder \",[30,0,[\"depthClass\"]]]]],[12],[13],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[10,0],[14,0,\"et-cell-content\"],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"        \"],[18,1,[[30,0,[\"cellValue\"]],[30,0,[\"columnValue\"]],[30,0,[\"rowValue\"]],[30,0,[\"cellMeta\"]],[30,0,[\"columnMeta\"]],[30,0,[\"rowMeta\"]],[30,0,[\"rowsCount\"]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,0,[\"cellValue\"]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[48,[30,1]],[[[1,\"    \"],[18,1,[[30,0,[\"cellValue\"]],[30,0,[\"columnValue\"]],[30,0,[\"rowValue\"]],[30,0,[\"cellMeta\"]],[30,0,[\"columnMeta\"]],[30,0,[\"rowMeta\"]],[30,0,[\"rowsCount\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"cellValue\"]]],[1,\"\\n\"]],[]]]],[]]]],[\"&default\"],false,[\"if\",\"unless\",\"ember-table-simple-checkbox\",\"action\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-table/components/ember-td/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});