define("ember-concurrency/-private/external/task-decorators", ["exports", "ember-concurrency/-private/external/task-factory"], function (_exports, _taskFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createTaskDecorator = createTaskDecorator;
  _exports.createTaskGroupDecorator = createTaskGroupDecorator;
  _exports.decoratorWithParams = decoratorWithParams;
  _exports.lastValue = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function taskFromPropertyDescriptor(target, key, descriptor) {
    let params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    let factoryClass = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : _taskFactory.TaskFactory;
    let {
      initializer,
      get,
      value
    } = descriptor;
    let taskFn;
    if (initializer) {
      taskFn = initializer.call(undefined);
    } else if (get) {
      taskFn = get.call(undefined);
    } else if (value) {
      taskFn = value;
    }
    taskFn.displayName = "".concat(key, " (task)");
    let tasks = new WeakMap();
    let options = params[0] || {};
    let factory = new factoryClass(key, taskFn, options);
    factory._setupEmberKVO(target);
    return {
      get() {
        let task = tasks.get(this);
        if (!task) {
          task = factory.createTask(this);
          tasks.set(this, task);
        }
        return task;
      }
    };
  }
  function taskGroupPropertyDescriptor(_target, key, _descriptor) {
    let params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    let factoryClass = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : _taskFactory.TaskFactory;
    let taskGroups = new WeakMap();
    let options = params[0] || {};
    let factory = new factoryClass(key, null, options);
    return {
      get() {
        let task = taskGroups.get(this);
        if (!task) {
          task = factory.createTaskGroup(this);
          taskGroups.set(this, task);
        }
        return task;
      }
    };
  }

  // Cribbed from @ember-decorators/utils
  function isFieldDescriptor(possibleDesc) {
    let [target, key, desc] = possibleDesc;
    return possibleDesc.length === 3 && typeof target === 'object' && target !== null && typeof key === 'string' && (typeof desc === 'object' && desc !== null && 'enumerable' in desc && 'configurable' in desc || desc === undefined) // TS compatibility ???
    ;
  }

  function decoratorWithParams(descriptorFn) {
    return function () {
      for (var _len = arguments.length, params = new Array(_len), _key2 = 0; _key2 < _len; _key2++) {
        params[_key2] = arguments[_key2];
      }
      if (isFieldDescriptor(params)) {
        return descriptorFn(...params);
      } else {
        return function () {
          for (var _len2 = arguments.length, desc = new Array(_len2), _key3 = 0; _key3 < _len2; _key3++) {
            desc[_key3] = arguments[_key3];
          }
          return descriptorFn(...desc, params);
        };
      }
    };
  }
  function createDecorator(fn) {
    let baseOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let factoryClass = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _taskFactory.TaskFactory;
    return decoratorWithParams(function (target, key, descriptor) {
      let [userOptions] = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      let mergedOptions = Object.assign({}, _objectSpread(_objectSpread({}, baseOptions), userOptions));
      return fn(target, key, descriptor, [mergedOptions], factoryClass);
    });
  }
  function createTaskDecorator() {
    let baseOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let factoryClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _taskFactory.TaskFactory;
    return createDecorator(taskFromPropertyDescriptor, baseOptions, factoryClass);
  }
  function createTaskGroupDecorator() {
    let baseOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let factoryClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _taskFactory.TaskFactory;
    return createDecorator(taskGroupPropertyDescriptor, baseOptions, factoryClass);
  }
  const lastValue = decoratorWithParams(function (_target, _key, descriptor) {
    let [taskName] = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    const {
      initializer
    } = descriptor;
    delete descriptor.initializer;
    return {
      get() {
        let lastInstance = this[taskName].lastSuccessful;
        if (lastInstance) {
          return lastInstance.value;
        }
        if (initializer) {
          return initializer.call(this);
        }
        return undefined;
      }
    };
  });
  _exports.lastValue = lastValue;
});