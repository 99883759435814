define("ember-data-storefront/-private/record-query", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class RecordQuery {
    constructor(store, type, id) {
      let params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      this.store = store;
      this.type = type;
      this.id = id;
      this.params = params;

      // if we have no params, we can use the model from
      // the store if it exists, nice lil shortcut here.
      this.value = Object.keys(this.params).length === 0 ? this.store.peekRecord(type, id) : null;
    }
    run() {
      // if we're running a query in storefront we always want
      // a blocking promise, so we force reload true.
      let options = _objectSpread(_objectSpread({}, {
        reload: true
      }), this.params);
      return this.store.findRecord(this.type, this.id, options).then(record => {
        this.value = record;
        return record;
      });
    }
  }
  _exports.default = RecordQuery;
});