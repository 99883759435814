define("ember-composable-helpers/-private/closure-action", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    __loader
  } = _ember.default;
  let ClosureActionModule = {
    ACTION: null
  };
  if ('ember-htmlbars/keywords/closure-action' in __loader.registry) {
    ClosureActionModule = __loader.require('ember-htmlbars/keywords/closure-action');
  } else if ('ember-routing-htmlbars/keywords/closure-action' in __loader.registry) {
    ClosureActionModule = __loader.require('ember-routing-htmlbars/keywords/closure-action');
  }
  var _default = ClosureActionModule.ACTION;
  _exports.default = _default;
});