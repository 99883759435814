define("ember-data-storefront/-private/cache", ["exports", "ember-data-storefront/-private/utils/get-key"], function (_exports, _getKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    A cache for queries.
  */
  class Cache {
    constructor() {
      this.store = {};
    }
    get() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      let key = (0, _getKey.cacheKey)(args);
      return this.store[key];
    }
    put(query) {
      let key = (0, _getKey.queryCacheKey)(query);
      this.store[key] = query;
      return query;
    }
    all() {
      return Object.keys(this.store).map(key => this.store[key]);
    }
  }
  _exports.default = Cache;
});