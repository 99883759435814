define("@videoly/ember-trix-next/index", ["exports", "@videoly/ember-trix-next/components/trix-editor/toolbar"], function (_exports, _toolbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "BUTTONS", {
    enumerable: true,
    get: function get() {
      return _toolbar.BUTTONS;
    }
  });
});