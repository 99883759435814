define("ember-concurrency/-private/external/environment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Environment = _exports.DEFAULT_ENVIRONMENT = void 0;
  class Environment {
    assert() {}
    async(callback) {
      Promise.resolve().then(callback);
    }
    reportUncaughtRejection() {
      this.async(error => {
        throw error;
      });
    }
    defer() {
      let deferable = {
        promise: null,
        resolve: null,
        reject: null
      };
      let promise = new Promise((resolve, reject) => {
        deferable.resolve = resolve;
        deferable.reject = reject;
      });
      deferable.promise = promise;
      return deferable;
    }
    globalDebuggingEnabled() {
      return false;
    }
  }
  _exports.Environment = Environment;
  const DEFAULT_ENVIRONMENT = new Environment();
  _exports.DEFAULT_ENVIRONMENT = DEFAULT_ENVIRONMENT;
});