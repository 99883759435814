define("ember-printable-pages/util-models/report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class Report {
    constructor() {
      _defineProperty(this, "chapterMap", {});
      _defineProperty(this, "chapters", []);
    }
    get chapterCount() {
      var _this$chapters;
      return (_this$chapters = this.chapters) === null || _this$chapters === void 0 ? void 0 : _this$chapters.length;
    }
    get lastPage() {
      var _this$chapters2, _this$chapters3;
      return (_this$chapters2 = this.chapters) === null || _this$chapters2 === void 0 ? void 0 : (_this$chapters3 = _this$chapters2[this.chapterCount - 1]) === null || _this$chapters3 === void 0 ? void 0 : _this$chapters3.endPage;
    }
    get isFinishedRendering() {
      return !this.chapters.find(c => !c.isFinishedRendering);
    }
    addChapter(chapter) {
      this.chapterMap[chapter.id] = chapter;
      this.chapters.push(chapter);
    }
  }
  _exports.default = Report;
});