define("ember-svg-jar/inlined/tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_347_1281)\"><path fill=\"#fff\" d=\"M0 0h159v159H0z\"/><path d=\"M137.277 0H21.119C9.503 0 0 9.503 0 21.12v116.157c0 11.616 9.503 21.119 21.12 21.119h116.157c11.616 0 21.119-9.503 21.119-21.119V21.119C158.396 9.503 148.893 0 137.277 0zm10.559 137.277c0 5.808-4.752 10.561-10.56 10.561l-116.158-.001c-5.808 0-10.56-4.753-10.56-10.561V21.118c0-5.807 4.753-10.56 10.561-10.56h116.158c5.808 0 10.56 4.752 10.56 10.56l-.001 116.159z\" fill=\"#000\"/><path d=\"M126.717 52.799a3.53 3.53 0 00-3.52 3.52v45.759a3.53 3.53 0 003.52 3.519 3.53 3.53 0 003.519-3.519v-45.76a3.53 3.53 0 00-3.519-3.52zM85.182 53.855c-1.408-1.408-3.52-1.408-4.928 0s-1.408 3.52 0 4.928L97.15 75.679H30.8a3.53 3.53 0 00-3.52 3.52 3.53 3.53 0 003.52 3.52h66.35L80.254 99.614c-1.408 1.408-1.408 3.519 0 4.928.705.703 1.584 1.055 2.464 1.055.88 0 1.76-.352 2.464-1.055l22.88-22.88c1.408-1.408 1.408-3.52 0-4.928l-22.88-22.88z\" fill=\"#000\"/></g><defs><clipPath id=\"clip0_347_1281\"><path fill=\"#fff\" d=\"M0 0h159v159H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "159",
      "height": "159",
      "viewBox": "0 0 159 159",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});