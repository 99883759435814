define("ember-local-storage/session/object", ["exports", "@ember/object/proxy", "ember-local-storage/mixins/object"], function (_exports, _proxy, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _proxy.default.extend(_object.default, {
    _storageType: 'session'
  });
  _exports.default = _default;
});