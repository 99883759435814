define("ember-svg-jar/inlined/file-download-regular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.875 8.607a.437.437 0 00-.438-.438h-.875a.437.437 0 00-.437.438v3.063H4.343a.438.438 0 00-.308.748l2.636 2.616c.182.18.476.18.657 0l2.637-2.616a.438.438 0 00-.308-.748H7.875V8.607zm5.608-5.035L10.425.514A1.75 1.75 0 009.189 0H1.75A1.756 1.756 0 000 1.753v15.164c0 .966.784 1.75 1.75 1.75h10.497c.967 0 1.753-.784 1.753-1.75V4.811a1.76 1.76 0 00-.517-1.239zM9.332 1.895l2.774 2.774H9.332V1.895zm2.918 15.022H1.75V1.753h5.832v3.791c0 .485.39.875.875.875h3.793v10.498z\" fill=\"#1C3959\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "14",
      "height": "19",
      "viewBox": "0 0 14 19",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});