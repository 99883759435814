define("ember-container-query/components/container-query", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _object, _component2, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (element this.tagName) as |Tag|}}
    <Tag
      class="container-query"
      data-test-container-query
      {{container-query
        dataAttributePrefix=@dataAttributePrefix
        debounce=@debounce
        features=@features
        onQuery=this.updateState
      }}
      ...attributes
    >
      {{yield (hash
        dimensions=this.dimensions
        features=this.queryResults
      )}}
    </Tag>
  {{/let}}
  */
  {
    "id": "ovDTIMvS",
    "block": "[[[44,[[28,[37,1],[[30,0,[\"tagName\"]]],null]],[[[1,\"  \"],[8,[30,1],[[24,0,\"container-query\"],[17,2],[4,[38,2],null,[[\"dataAttributePrefix\",\"debounce\",\"features\",\"onQuery\"],[[30,3],[30,4],[30,5],[30,0,[\"updateState\"]]]]]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,6,[[28,[37,4],null,[[\"dimensions\",\"features\"],[[30,0,[\"dimensions\"]],[30,0,[\"queryResults\"]]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]],[\"Tag\",\"&attrs\",\"@dataAttributePrefix\",\"@debounce\",\"@features\",\"&default\"],false,[\"let\",\"element\",\"container-query\",\"yield\",\"hash\"]]",
    "moduleName": "ember-container-query/components/container-query.hbs",
    "isStrictMode": false
  });
  let ContainerQueryComponent = (_class = class ContainerQueryComponent extends _component2.default {
    constructor() {
      var _this$args$tagName;
      super(...arguments);
      _initializerDefineProperty(this, "dimensions", _descriptor, this);
      _initializerDefineProperty(this, "queryResults", _descriptor2, this);
      // The dynamic tag is restricted to be immutable
      _defineProperty(this, "tagName", (_this$args$tagName = this.args.tagName) !== null && _this$args$tagName !== void 0 ? _this$args$tagName : 'div');
    }
    updateState(_ref) {
      let {
        dimensions,
        queryResults
      } = _ref;
      this.dimensions = dimensions;
      this.queryResults = queryResults;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dimensions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "queryResults", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateState", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateState"), _class.prototype)), _class);
  _exports.default = ContainerQueryComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContainerQueryComponent);
});