define("@algonauti/ember-active-storage/services/active-storage", ["exports", "@ember/utils", "@algonauti/ember-active-storage/model/blob", "rsvp", "@ember/service", "@algonauti/ember-active-storage/-private/uploader", "@ember/debug", "@ember/application"], function (_exports, _utils, _blob, _rsvp, _service, _uploader, _debug, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class ActiveStorageService extends _service.default {
    get _config() {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment') || {};
      return config['ember-active-storage'] || {};
    }
    upload(file, urlOrCallbacks) {
      let callbacks = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let url;
      if ((0, _utils.isPresent)(urlOrCallbacks)) {
        if ((0, _utils.typeOf)(urlOrCallbacks) == 'string') {
          url = urlOrCallbacks;
        } else if ((0, _utils.typeOf)(urlOrCallbacks) == 'object') {
          (false && !((0, _utils.isPresent)(this._config['url'])) && (0, _debug.assert)("If not explicitly passed, URL must be set on ENV['ember-active-storage'] = { url: '...' }", (0, _utils.isPresent)(this._config['url'])));
          callbacks = urlOrCallbacks;
          url = this._config['url'];
        }
      } else {
        (false && !((0, _utils.isPresent)(this._config['url'])) && (0, _debug.assert)("If not explicitly passed, URL must be set on ENV['ember-active-storage'] = { url: '...' }", (0, _utils.isPresent)(this._config['url'])));
        url = this._config['url'];
      }
      const uploader = new _uploader.default(_objectSpread({
        headers: this.headers
      }, callbacks));
      return new _rsvp.Promise((resolve, reject) => {
        _blob.default.build(file).then(blob => {
          uploader.upload(blob, url, resolve, reject);
        });
      });
    }
  }
  _exports.default = ActiveStorageService;
});